import React from 'react'
import './index.css'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Pagination, Autoplay, Mousewheel} from 'swiper';
import SwiperCore from 'swiper/core/core';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'; // Navigation module
import './index.css'

SwiperCore.use([Autoplay, Pagination, Mousewheel])
const GlideImg = () => {
    
    const text = (text1, text2, link, width, height) => {
        return (<a href={link} target="_blank" rel="noreferrer"><div className="textContainer" style={{width: width, height: height}}><span className="textLine1">{text1}</span><span className="textLine2">{text2}</span></div></a>)
    }

    const customPage = (index, className) => {
        return ('<span class="' + className + '" style="background-image: url(./background'+ ( index  + 1 ) +'.png); border: 6px solid red"></span>')
    }

    return (
    <div className="containerOfGlider" id="home">
        <div className="leftScorll1">
            <div className="topText"></div>
            <div className="bottomIcon1"></div>
        </div>
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            autoplay={{
                delay: 5000,
                stopOnLastSlide: false,
                disableOnInteraction: true
            }}
            pagination={{
                clickable: true,
                renderBullet: customPage,
                // dynamicBullets: true,
                dynamicMainBullets: 1
            }}
            mousewheel={{
                forceToAxis: true,
              }}
            preventInteractionOnTransition={true}
            direction={'horizontal'}
            speed={3000}
            grabCursor
            initialSlide={0} // 初始化显示哪一个
            loop // 是否循环
            >
            <SwiperSlide><div className='img5'>{text('一块屏幕改变命运', '', 'https://mp.weixin.qq.com/s/2yVFIGY-XGhwpbZ02gyI_g', '26rem', '4rem')}</div></SwiperSlide>
            <SwiperSlide><div className='img1'>{text('灾害援助', '助力河南学校灾后重建', 'https://mp.weixin.qq.com/s/sgERAL2_IcwQ3JSi4CWRxg', '22rem', '10rem')}</div></SwiperSlide>
            <SwiperSlide><div className='img2'>{text('微光计划', '用点点微光，照亮你我他', 'https://mp.weixin.qq.com/s/auHIbSLeP9nM0glWLRM8LA', '30rem', '10rem')}</div></SwiperSlide>
            <SwiperSlide><div className='img3'>{text('志愿者公益', '用行动传递爱与力量', 'https://mp.weixin.qq.com/s/_WhApUFAaCob3MJ22C8zNA', '20rem', '10rem')}</div></SwiperSlide>
            <SwiperSlide><div className='img4'>{text('一间教室', '搭建有温度、无界限的综合素质教育空间', 'https://mp.weixin.qq.com/s/UPDbtBTGqhlKQC7CRUjoCw', '38rem', '10rem')}</div></SwiperSlide>
            {/* <SwiperSlide><div className='img6'>{text()}</div></SwiperSlide> */}
            {/* <span slot="container-start">Container Start</span>
            <span slot="container-end">Container End</span>
            <span slot="wrapper-start">Wrapper Start</span>
            <span slot="wrapper-end">Wrapper End</span> */}
        </Swiper> 
        
    </div>)
}

export default GlideImg