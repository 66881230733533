/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react' 
import { useEffect } from 'react'
import Footer from '../../Honor/footer'
import Background from '../../static/screen1.jpg'
import PageRightNewsList from '../components/PageRightNewsList'
import './index.css'

const ScreenOfNews = (props) => {
  const {setColor} = props

  useEffect(()=>{
    setColor('black')
  }, [])
  return (
  <>
    <div className="lineTop"></div>
    <div className="topBlank"></div>
    {/* <div></div> */}
    <div style={{position: 'relative', height: '170rem',overflow:'hidden'}}>
      <div className={'aboutUsContainer'}>
        <div className="content">
          <div className="englishIntro">微光计划</div>
          <div className="chineseIntro">微光计划</div>
          <div className="lineBehindNews"></div>
          <div className='newsContentInDetailPage'>
            <div className='PageleftNewsDetail'>
            &nbsp; &nbsp;3月13日，北京2022年冬残奥会落下帷幕。赛场上，运动员让所有人看见，他们不受身体束缚的灵魂。生活中，他们也在各行各业里，努力让自己闪光。
            <br/>&nbsp; &nbsp;但靠一双手获得一份体面的收入，对他们依旧不容易。据中国残疾人联合会数据统计，我国残疾人总人数已超过8500万，但就业比例并不高。
            <br/>&nbsp; &nbsp;作为一个在线职业教育平台，网易云课堂始终关注弱势群体的就业问题。
            <br/>&nbsp; &nbsp;2019年，我们开始为残疾人提供灵活办公的工作机会。2021年，我们一共向他们开放将近1000个工作岗位。2022年，我们希望不仅要授人以鱼，还要授人以渔。
            <br/>&nbsp; &nbsp;我们联合微软公司共同发起教育助残公益行动「微光计划」，向全社会8500万名残疾人免费开放12个岗位的在线职业技能课程。其中，既有微软擅长的办公软件培训课，也有网易云课堂在家就能接单赚钱的商业插画课和播音配音课。
            <br/>&nbsp; &nbsp;中国冬残奥历史上首枚金牌获得者、 中国轮椅冰壶运动员王蒙将作为联合发起人，把“微光计划”的信息传递给更多残疾人。此外，我们还邀请了Angelababy杨颖、胡彦斌、欧阳娜娜、汪苏泷、王子异、张紫宁担任“微光计划”的宣传大使，共同呼吁关注残疾人的就业问题。
                <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1672283138088image+%281%29.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '32rem'}}></div>
                &nbsp; &nbsp;自“微光计划”上线以来，已有超过7500名学员陆续开始学习IT和副业课程，超过3000位残疾人在本次计划中受益，其中12人已通过培训顺利就业。
                <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/16722832033624371671533291_.pic.jpg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '32rem'}}></div>
                &nbsp; &nbsp;“微光计划”致力于维护残疾人平等参与、公平发展的权利，具有积极履责的奉献精神并具有广泛的社会影响力。自上线以来，“微光计划”收到了社会各界的许多关注与认可，斩获多项大奖：2022年7月，“微光计划”荣获2022年度南方周末“杰出责任传播案例”奖；2022年11月，“微光计划”入选教育部“能者为师”典型案例；2022年12月，“微光计划”荣获第十二届公益节“2022年度公益项目奖”……
                <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1672283359985image4.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '32rem'}}></div>
            </div>
            <div className='lineMiddleInArtical'></div>
            <div className='NewsRightInArticla'>
              {/* <div className="chineseIntro">相关报道</div> */}
              <PageRightNewsList
                Background={Background}
                title={'网易一块屏'}
                content={'从2018年起，网易公益发起“一块屏”项目，开始将网课直播引入偏远地区学校进行试点。2019年，网易公益“一块屏”在数字科技推动教育普惠上迈出了重要一步，为四川省十余个县超百所学校正式开设了网课直播班，上万学子因此受益。'}
                link={"/Artical/ScreenOfNews"}
              ></PageRightNewsList>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </>  
    )
}

export default ScreenOfNews