import React, { useState } from 'react'
import Footer from './footer'
import './index.css'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Controller, EffectCoverflow, Scrollbar } from 'swiper';
import SwiperCore from 'swiper/core/core';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'; // Navigation module
import { Link } from "react-router-dom";


SwiperCore.use([Controller, EffectCoverflow, Scrollbar])
const HonorContainer = () => {
    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);

    return (
        <div className="containerOfHonor" id="honor">
            <div className="containerRightHonor">
                <div className="leftContainerHonor">
                    <div className="maskTop"></div>
                    <div className="maskBottom"></div>
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={3}
                        onSlideChange={() => {}}
                        onSwiper={setFirstSwiper}
                        mousewheel={{
                            forceToAxis: true,
                        }}
                        grabCursor={true}
                        direction={'vertical'}
                        initialSlide={0} // 初始化显示哪一个
                        loop={true} // 是否循环
                        centeredSlides={true}
                        centeredSlidesBounds={true}
                        slideToClickedSlide={true}
                        controller={{ control: secondSwiper }}
                        Scrollbar={{
                            hide: false,
                            draggable: true,
                        }}
                        >
                            <SwiperSlide>2020年入选“脱贫攻坚 志愿服务在行动”民政部推荐案例</SwiperSlide>
                            <SwiperSlide>2021年金钥匙·优质教育荣誉奖</SwiperSlide>
                            <SwiperSlide>2020年度公益集体奖</SwiperSlide>
                            <SwiperSlide>2020年度杭州最具影响力网络公益项目</SwiperSlide>
                            <SwiperSlide>2021年入选北京民营企业“科技创新”和“社会责任”百强榜单</SwiperSlide>
                            <SwiperSlide>2021年入选“北京民办教育践行社会责任公益展示”活动专栏</SwiperSlide>
                            <SwiperSlide>2021年度新锐责任企业</SwiperSlide>
                            <SwiperSlide>2020年金钥匙·优质教育荣誉奖</SwiperSlide>
                            <SwiperSlide>2021年度责任互联网公司</SwiperSlide>
                            <SwiperSlide>2020年企业社会责任教育项目优秀案例</SwiperSlide>
                            <SwiperSlide>2021中国公益项目品牌榜</SwiperSlide>
                            <SwiperSlide>2021年全球数字经济大会组委会感谢信</SwiperSlide>
                            <SwiperSlide>2021年互联网企业社会责任奖</SwiperSlide>
                            <SwiperSlide>2021年CSR环球创新典范奖及抗疫先锋致敬奖</SwiperSlide>
                            <SwiperSlide>2021年东西部扶贫协作爱心单位</SwiperSlide>
                            <SwiperSlide>2021年商务部感谢信</SwiperSlide>
                            <SwiperSlide>2021年度社会责任示范企业</SwiperSlide>
                            <SwiperSlide>“2021互联网企业社会责任”奖</SwiperSlide>
                            <SwiperSlide>2022年度南方周末“杰出责任传播案例”奖</SwiperSlide>
                            <SwiperSlide>北京民营企业社会责任百强2021年度第8位</SwiperSlide>
                            <SwiperSlide>“2022年 CSR CHINA TOP100年度最佳责任企业品牌”</SwiperSlide>
                            <SwiperSlide>入选“能者为师”典型案例</SwiperSlide>
                            <SwiperSlide>第十二届公益节“2022年度公益项目奖”</SwiperSlide>
                            <SwiperSlide>2022年北京信息通信行业适老化及无障碍服务“优秀案例”</SwiperSlide>
                            <SwiperSlide>第十七届人民企业社会责任奖“年度案例奖”</SwiperSlide>
                            <SwiperSlide>第十二届公益节“2022年度公益映像奖”</SwiperSlide>
                            <SwiperSlide>腾讯新闻2022“中国益公司”企业社会责任评选“社会实践杰出企业</SwiperSlide>
                            <SwiperSlide>2022牛客最佳校招雇主评选“最具社会责任校招雇主”</SwiperSlide>
                            <SwiperSlide>2022臻善奖“年度臻善企业”大奖</SwiperSlide>
                            <SwiperSlide>CCF 2022技术公益年度案例集</SwiperSlide>

                    </Swiper>
                    <div className="maskMid"></div>
                </div>
                <div className="rightContainerHonor">
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={3}
                        onSlideChange={() => {}}
                        onSwiper={setSecondSwiper}
                        mousewheel={{
                            forceToAxis: true,
                        }}
                        grabCursor={true}
                        direction={'vertical'}
                        initialSlide={0} // 初始化显示哪一个
                        loop={true} // 是否循环
                        centeredSlides={true}
                        centeredSlidesBounds={true}
                        controller={{ control: firstSwiper }}
                        effect={"coverflow"}
                        EffectCoverflow={{
                            rotate: 150,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        >
                            <SwiperSlide><div className="honor1"></div></SwiperSlide>
                            <SwiperSlide><div className="honor2"></div></SwiperSlide>
                            <SwiperSlide><div className="honor3"></div></SwiperSlide>
                            <SwiperSlide><div className="honor4"></div></SwiperSlide>
                            <SwiperSlide><div className="honor5"></div></SwiperSlide>
                            <SwiperSlide><div className="honor6"></div></SwiperSlide>
                            <SwiperSlide><div className="honor7"></div></SwiperSlide>
                            <SwiperSlide><div className="honor8"></div></SwiperSlide>
                            <SwiperSlide><div className="honor9"></div></SwiperSlide>
                            <SwiperSlide><div className="honor10"></div></SwiperSlide>
                            <SwiperSlide><div className="honor11"></div></SwiperSlide>
                            <SwiperSlide><div className="honor12"></div></SwiperSlide>
                            <SwiperSlide><div className="honor13"></div></SwiperSlide>
                            <SwiperSlide><div className="honor14"></div></SwiperSlide>
                            <SwiperSlide><div className="honor15"></div></SwiperSlide>
                            <SwiperSlide><div className="honor16"></div></SwiperSlide>
                            <SwiperSlide><div className="honor17"></div></SwiperSlide>
                            <SwiperSlide><div className="honor13"></div></SwiperSlide>
                            <SwiperSlide><div className="honor18"></div></SwiperSlide>
                            <SwiperSlide><div className="honor19"></div></SwiperSlide>
                            <SwiperSlide><div className="honor20"></div></SwiperSlide>
                            <SwiperSlide><div className="honor21"></div></SwiperSlide>
                            <SwiperSlide><div className="honor22"></div></SwiperSlide>
                            <SwiperSlide><div className="honor23"></div></SwiperSlide>
                            <SwiperSlide><div className="honor24"></div></SwiperSlide>
                            <SwiperSlide><div className="honor25"></div></SwiperSlide>
                            <SwiperSlide><div className="honor26"></div></SwiperSlide>
                            <SwiperSlide><div className="honor27"></div></SwiperSlide>
                            <SwiperSlide><div className="honor28"></div></SwiperSlide>
                            <SwiperSlide><div className="honor29"></div></SwiperSlide>
                            <SwiperSlide><div className="honor30"></div></SwiperSlide>
                            
                    </Swiper>
                </div>
            </div>
            <Link to="/"><div className="leftScorll4" onClick={() => console.log(111)}>
                <div className="topTextLeftHonor"></div>
                <div className="topTextRightHonor"></div>
                <div className="bottomIconHonor"></div>
            </div></Link>
            <div className="rightBackground"></div>
            <Footer></Footer>
        </div>
    )
}

export default HonorContainer
