import React from 'react'
import Footer from '../../../Honor/footer'
import pic from '../../../static/jian1.jpg'
import pic1 from '../../../static/jian2.jpg'


const PartyLast = () => {
  return (
    <div style={{position: 'relative', height: '80rem'}}>
            <div className={'aboutUsContainer'} style={{marginTop: '-4rem'}}>
                <div className="content" style={{marginBottom: '4rem'}}>
                    <div className="englishIntro">Party Affairs</div>
                    <div className="chineseIntro">党建活动</div>
                </div>
                <h2 style={{marginBottom:30}}>建功新时代，喜迎二十大——网易党委联合北京网易公益基金会组织开展“为党庆生 向党献礼”主题党日活动</h2>
                <p>
                为庆祝中国共产党诞辰101周年，营造浓厚的庆七一氛围，向党的二十大献礼，2021年6月30日，网易党委联合北京网易公益基金会组织党员、入党积极分子举行了“为党庆生 向党献礼”主题党日活动。
                整个活动包括“党史知识竞答”“重温入党誓词”“我与党旗合个影”“共享庆生蛋糕”四个环节。在党史知识竞赛环节，30余名党员、入党积极分子踊跃抢答，现场氛围十分热烈，充分展现了同学们朝气蓬勃、奋发向上的精神风貌。伴着激动、高涨的心情，党员们举起右拳、面向党旗，再次重温了入党誓词，感悟入党初心。
                庆祝活动的同时，党员、入党积极分子们还通过慈善捐助平台积极奉献爱心、捐助善款，响应“致力共同富裕·强国复兴有我”的号召，以实际行动向党的二十大献礼。一位入党积极分子说，“我要捐101元，祝贺中国共产党101岁生日快乐！”
                最后，网易党委书记带领党委班子成员，第一时间传达学习了北京市第十三次党代会会议精神，并作交流发言。北京网易公益基金会党员代表在学习会上表示“要不断坚持党领导，引领基金会健康发展；凝聚多方力量，将公益事业进行到底，把优质教育资源提供给更多有需要的困境学生”。
                </p>
                <div className='PicLeftArtical'>
                <div className="articalImg" style={{backgroundImage: `url(${pic})`,marginTop:'25rem'}}></div>
                <div className="articalImg" style={{backgroundImage: `url(${pic1})`,marginTop:'25rem'}}></div>
                </div>
            </div>
        <Footer></Footer>
     </div>
  )
}

export default PartyLast