/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react' 
import { useEffect } from 'react'
import Footer from '../../Honor/footer'
import Background from '../../static/screen1.jpg'
import PageRightNewsList from '../components/PageRightNewsList'
import './index.css'

const InfomationSecondOfNews = (props) => {
  const {setColor} = props

  useEffect(()=>{
    setColor('black')
  }, [])
  return (
  <>
    <div className="lineTop"></div>
    <div className="topBlank"></div>
    {/* <div></div> */}
    <div style={{position: 'relative', height: '127rem'}}>
      <div className={'aboutUsContainer'}>
        <div className="content">
          <div className="englishIntro">News</div>
          <div className="chineseIntro">123无障爱</div>
          <div className="lineBehindNews"></div>
          <div className='newsContentInDetailPage'>
            <div className='PageleftNewsDetail'>

            心中有光,眼前有道,还有明星的语音陪伴。12月3日,又一年的“国际残疾人日”,网易有道词典携手欧阳娜娜、张艺兴等众明星发起#123无障爱#活动,特对个性化词典、明星语音、拍照翻译等功能进行了读屏适配,并正式发布有道词典无障碍beta版本,帮助视障人群也可以轻松高效地学习英语,在冬日里献上一份暖心且长久的“特殊礼包”。

              <div style={{backgroundImage: `url(https://shimo.youdao.com/uploader/f/Gwy1Ph2ORnF9Ekmx.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem',marginBottom: '1rem', height: '48rem', backgroundPosition: '50%'}}></div>

              作为一家以成就学习者“高效学习”为使命的智能学习公司,网易有道依托强大的互联网AI技术,围绕不同的学习场景,打造了一系列多元化的现象级产品和配套的智能服务。其中,有道词典以权威性和便捷性成为深受大众喜爱的学习工具。统计数据显示,目前有道词典的累计用户量已超9亿,在惠及绝大多数普通用户的同时,网易有道也将目光投向特殊人群,决心为广大视障学习者开启一条互联网“盲道”,帮助他们更好地融入当下的电子世界。  
	前不久,网易有道宣布正式加入中国信息无障碍产品联盟(简称CAPA)——所谓“信息无障碍”,即为任何人在任何情况下可以平等、便利、无障碍地获取和使用信息。2019年《视障人士在线社交报告》显示:中国有1700万视障人群,九成视障人士在生活中需要互联网,他们对微信等通讯类软件的需求高达99%,也同样有上网聊天、浏览视频以及线上学习的需求。但是,在过往的人机交互体验中,他们往往是被忽视和被遗忘的群体。  
	今年下半年,网易有道致力于对有道词典进行无障碍优化,通过用户研究、行业标准同步、技术支持等系列动作,为障碍用户保驾护航,让他们具备无障碍使用有道词典的可能。<br></br>  
  <br></br>
	而就在今天,网易有道词典上线#123无障爱#活动,面向广大视障用户发布有道词典无障碍beta版本。无障碍beta版本的网易有道词典,在个性化词典、明星语音、拍照翻译上都进行了读屏适配,最新的词典内部按钮也增添了读屏标签,提供了更多的打开方式,视障人群能够以“举手之劳”进入一个日新月异的数码世界。  
	更令人欣喜的是,欧阳娜娜、张艺兴、宋茜、张俪、汪苏泷、白举纲、郁可唯、董思成倾情加入有道词典#123无障爱#活动,关爱并助力视障者学习,进一步激发用户跟读练习的热情,并感受个性化的学习陪伴。  
	这是“科技助力教育公平”的又一落地举措。在网易有道看来,这一举措不仅可以提升产品的包容性和普适性,也更能彰显企业价值和品牌温度。未来,网易有道还将积极承担更多企业社会责任,助力更多学习者平等享受科技进步带来的便利。 
            </div>
            <div className='lineMiddleInArtical'></div>
            <div className='NewsRightInArticla'>
              {/* <div className="chineseIntro">相关报道</div> */}
              <PageRightNewsList
                Background={Background}
                title={'网易一块屏'}
                content={'从2018年起，网易公益发起“一块屏”项目，开始将网课直播引入偏远地区学校进行试点。2019年，网易公益“一块屏”在数字科技推动教育普惠上迈出了重要一步，为四川省十余个县超百所学校正式开设了网课直播班，上万学子因此受益。'}
                link={"/Artical/ScreenOfNews"}
              ></PageRightNewsList>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </>  
    )
}

export default InfomationSecondOfNews