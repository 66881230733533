import React from 'react' 
import Footer from '../../../Honor/footer'
import NewsModalPicLeft from '../../../MessageCenter/components/NewsModalPicLeft'
import pic1 from '../../../static/image2.jpg'


const Education = () => {

  return (
  <div style={{position: 'relative', height: '77rem'}}>
    <div className={'aboutUsContainer'}>
      <div className="content">
        <div className="englishIntro">Education Informationization</div>
        <div className="chineseIntro">教育信息化</div>
        <div className="projectContent">
          <NewsModalPicLeft
            Background={pic1}
            title={'网易一间教室'}
            content="一间教室是网易公益基金会对“一块屏项目”优化升级后的3.0版本，在清华大学脑与智能实验室科学家团队的支持和指导下，系统性信息化改造了废旧校舍，为学校提供了软硬系统的全面升级，为青少年与儿童建设友好的活动空间。
            通过平板电脑、多媒体设备提供有道特色课程，以提升青少年健康身体、良好社交和智慧思维为目标，为当地社区和学校提供有温度、无界限的综合素质教育空间，打造系统化与模块化的教育公益标准化模式。"
            link="/Artical/ClassOfNews"
          ></NewsModalPicLeft>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
  )
}

export default Education