import React from 'react' 
import './index.css'

const PageRightNewsList = (props) => {
  const {Background, title, content, link} = props
  return (
    <div className="PicTopArtical">
      <div className="articalImgInMessageCenter" style={{backgroundImage: `url(${Background})`}}></div>
      <div className=".articalTextInMessageCenter">
        <div className="topArticalSpan">
          <div className="topArticalTitle">{title}</div>
          <div className="topArticalContent">{content}</div>
        </div>
        <a className="BottomArticalLink" href={link} target="_blank" rel="noreferrer" >
          <div className="articalLinkText" style={{marginTop: '2rem'}}>查看详情</div>
          <div className="articalLinkArrow"  style={{marginTop: '3rem'}}></div>
        </a>
      </div>
    </div>
  )
}

export default PageRightNewsList