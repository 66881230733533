import React from 'react' 
import './index.css'
import Footer from '../../../Honor/footer'

const ContactUs = () => {
  return ( <div  style={{position: 'relative', height: '77rem'}}>
  <div className={'aboutUsContainer'}>
      <div className="content">
        <div className="englishIntro">Contact Us</div>
        <div className="chineseIntro">联系我们</div>
        <div className="supportUsText">
          {/* <span>
          地址：北京市海淀区西北旺东路10号院中关村软件园二期西区7号网易研发中心
          </span> */}
          <div className='map'></div>
          <br/>
          {/* <span>
          联系电话：<span style={{fontWeight: 'bold'}}>010-82558843</span>
          </span>
          <br/>
          <span>
          联系邮箱：<span style={{fontWeight: 'bold'}}>gongyijiaoyu@163.com</span>
          </span>
          <br/>
          <span>
          邮编：<span style={{fontWeight: 'bold'}}>100000</span>
          </span> */}
        </div>
      </div>
  </div>
  <Footer></Footer>
</div>)
}

export default ContactUs