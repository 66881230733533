/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import './index.css'
import { scrollToAnchor } from '../../util'
import PartyFirst from './page/party1'
import PartyLast from './page/party2'


const PartyContainer = (props) => {
    const { setPageState, setColor } = props
    const [hash, setTemp] = useState('')
    window.onhashchange=function(event){
        setTemp(event.newURL.split('#')[1])
        // headerNav(temp)
    }
    window.onload= (event) =>{
        window.location.hash = '#'
    }
    useEffect(()=>{
        setPageState('partyBuild')
        setColor('black')
    },[])
    return (
        <>
        <div className="lineTop"></div>
        <div className="topBackGround"></div>
        <div className='greatWallBackend'></div>
        <div className="lineTop"></div>
        <nav className="aboutUsNavList" style={{width: '16rem'}}>
                <a href="#/partyKnowledge" onClick={() => scrollToAnchor('fund')}>{hash === '/partyKnowledge' || hash === '' ? <span className="aboutUsTab">知识园地</span> : <span className="aboutUsTabNoAct">知识园地</span>}</a>
                <a href="#/partyActivity" onClick={() => scrollToAnchor('community')}>{hash === '/partyActivity' ? <span className="aboutUsTab">党建活动</span> : <span className="aboutUsTabNoAct">党建活动</span>}</a>
            </nav>
        {hash === '/partyKnowledge' || hash === '' ? <PartyFirst></PartyFirst> : null}
        {hash === '/partyActivity' ? <PartyLast></PartyLast>: null}
        
    </>
    )
}

export default PartyContainer