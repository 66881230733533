/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import './index.css'
import { scrollToAnchor } from '../../util'
import Epidemic from './pages/Epidemic'
import Disaster from './pages/Disaster'
import Accessibility from './pages/Accessibility'
import Education from './pages/Education'
import Screen from './pages/Screen'
import Volunteer from './pages/Volunteer'

const ProjectContainer = (props) => {
    const [hash, setTemp] = useState('')
    const { setPageState, setColor } = props

    useEffect(()=>{
        setPageState('projectIntroduction')
        setColor('black')
    },[])
    window.onhashchange=function(event){
        setTemp(event.newURL.split('#')[1])
        // headerNav(temp)
    }
    window.onload= (event) =>{
        window.location.hash = '#'
    }
    
    return (
        <>
            <div className="lineTop"></div>
            <div className="topBlank"></div>
            <nav className="aboutUsNavList">
                <a href="#/healthSupport" onClick={() => scrollToAnchor('healthSupport')}>{hash === '/healthSupport' || hash === '' ? <span className="aboutUsTab">抗疫支持</span> : <span className="aboutUsTabNoAct">抗疫支持</span>}</a>
                <a href="#/disasterRelief" onClick={() => scrollToAnchor('disasterRelief')}>{hash === '/disasterRelief' ? <span className="aboutUsTab">灾害救助</span> : <span className="aboutUsTabNoAct">灾害救助</span>}</a>
                <a href="#/neteasePad" onClick={() => scrollToAnchor('neteasePad')}>{hash === '/neteasePad' ? <span className="aboutUsTab">教育普惠</span> : <span className="aboutUsTabNoAct">教育普惠</span>}</a>
                <a href="#/youdaoEdu" onClick={() => scrollToAnchor('youdaoEdu')}>{hash === '/youdaoEdu' ? <span className="aboutUsTab">教育信息化</span> : <span className="aboutUsTabNoAct">教育信息化</span>}</a>
                <a href="#/infoAccessibility" onClick={() => scrollToAnchor('infoAccessibility')}>{hash === '/infoAccessibility' ? <span className="aboutUsTab">信息无障碍</span> : <span className="aboutUsTabNoAct">信息无障碍</span>}</a>
                <a href="#/staffWelfare" onClick={() => scrollToAnchor('staffWelfare')}>{hash === '/staffWelfare' ? <span className="aboutUsTab">志愿者公益</span> : <span className="aboutUsTabNoAct">志愿者公益</span>}</a>
            </nav>
            {hash === '/healthSupport' || hash === '' ? <Epidemic></Epidemic> : null}
            {hash === '/disasterRelief' ? <Disaster></Disaster> : null}
            {hash === '/infoAccessibility' ? <Accessibility></Accessibility> : null}
            {hash === '/youdaoEdu' ? <Education></Education> : null}
            {hash === '/neteasePad' ? <Screen></Screen> : null}
            {hash === '/staffWelfare' ? <Volunteer></Volunteer> : null}
        </>
        )
}

export default ProjectContainer