import React from 'react'
import Footer from '../../../Honor/footer'
import NewsModalPicLeft from '../../../MessageCenter/components/NewsModalPicLeft'
import pic from '../../../static/part1.png'
import pic1 from '../../../static/part2.png'


const PartyFirst = () => {
  return (
    <div style={{position: 'relative', height: '80rem'}}>
            <div className={'aboutUsContainer'} style={{marginTop: '-4rem'}}>
                <div className="content" style={{marginBottom: '4rem'}}>
                    <div className="englishIntro">Party Affairs</div>
                    <div className="chineseIntro">党建园地</div>
                </div>
                <NewsModalPicLeft
                    Background={pic}
                    title={'习近平：高举中国特色社会主义伟大旗帜 为全面建设社会主义现代化国家而团结奋斗——在中国共产党第二十次全国代表大会上的报告'}
                    content="中国共产党第二十次全国代表大会在北京时间十月十六日召开，党的二十大是在全党全国各族人民迈上全面建设社会主义现代化国家新征程、向第二个百年奋斗目标进军的关键时刻召开的一次十分重要的大会。"
                    link="https://www.12371.cn/2022/10/25/ARTI1666705047474465.shtml"
                ></NewsModalPicLeft>
                <NewsModalPicLeft
                    Background={pic1}
                    title={'中国共产党党章（中国共产党第二十次全国代表大会部分修改，2022年10月22日通过）'}
                    content="中国共产党是中国工人阶级的先锋队，同时是中国人民和中华民族的先锋队，是中国特色社会主义事业的领导核心，代表中国先进生产力的发展要求，代表中国先进文化的前进方向，代表中国最广大人民的根本利益。党的最高理想和最终目标是实现共产主义。"
                    link="https://www.12371.cn/2022/10/26/ARTI1666788342244946.shtml"
                ></NewsModalPicLeft>
            </div>
        <Footer></Footer>
     </div>
  )
}

export default PartyFirst