import React from 'react';
import './index.css'

const Footer = () => {
  return (
    <div className="footer">
            <div className="footerTop">
                <div className="footerTopLeft">
                    <div className="footerTopLeftlogo"><span className='BottomLogo' ></span><span className='BottomTitle'>北京网易公益基金会</span></div>
                    <div className="togetherImg"></div>
                </div>
                <div className="footerTopMid">
                    <span className="footerTopMidtitle">联系方式</span>
                    <span className="footerTopMidemail">邮箱：gongyijiaoyu@163.com</span>
                    <span className="footerTopMidaddress">地址：北京市海淀区西北旺东路10号院中关村软件园西区7号楼B座5层B5-26 </span>
                </div>
                <div className="footerTopRight">
                    <div className="leftQrcode">
                        <div className="qrcodeContainerLeft">
                            <div className="leftQrcodeImg"></div>
                        </div>
                        <span className="leftQrcodeText">微信</span>
                    </div>
                    <div className="rightQrcode">
                        <div className="qrcodeContainerRight">
                            <div className="rightQrcodeImg"></div>
                        </div>
                        <span className="rightQrcodeText">微博</span>
                    </div>
                </div>
            </div>
            <div className="footerBottom">©2019 网易公司 粤B2-2009019</div>
        </div>
  )
}

export default Footer