/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react' 
import { useEffect } from 'react'
import Footer from '../../Honor/footer'
import Background from '../../static/screen1.jpg'
import PageRightNewsList from '../components/PageRightNewsList'
import './index.css'

const DetailOfNews = (props) => {
  const {setColor} = props

  useEffect(()=>{
    setColor('black')
  }, [])
  return (
  <>
    <div className="lineTop"></div>
    <div className="topBlank"></div>
    {/* <div></div> */}
    <div style={{position: 'relative', height: '122rem'}}>
      <div className={'aboutUsContainer'}>
        <div className="content">
          <div className="englishIntro">Fight the Epidemic</div>
          <div className="chineseIntro">抗疫支持</div>
          <div className="lineBehindNews"></div>
          <div className='newsContentInDetailPage'>
            <div className='PageleftNewsDetail'>
              据不完全统计，疫情期间驰援湖北的医护人员70%是有孩子的。为了抗击疫情，他们离开了“小家”去救助“大家”， 他们的孩子成为了某种意义上的“留守儿童”，也需要更多人的关注和支持。
              2020年4月13日，北京网易公益基金会与中国人口福利基金会在京举办爱心捐赠仪式。北京网易公益基金会联合网易有道向中国人口福利基金会捐赠1700支有道词典笔，用于关爱援鄂抗疫医护人员及其子女，致谢英雄及其背后的“守望者”。希望通过这款产品，帮助援鄂抗疫医护人员及其子女更轻松高效地学习和进步。
              <div style={{backgroundImage: `url(https://shimo.youdao.com/uploader/f/BF5kE7i276pdrWtO.png)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', marginTop: '3rem'}}></div>
              <div style={{backgroundImage: `url(https://shimo.youdao.com/uploader/f/ZJDK4n3v3f4YlyCf.jpg)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', marginTop: '3rem'}}></div>
            </div>
            <div className='lineMiddleInArtical'></div>
            <div className='NewsRightInArticla'>
              {/* <div className="chineseIntro">相关报道</div> */}
              <PageRightNewsList
                Background={Background}
                title={'网易一块屏'}
                content={'从2018年起，网易公益发起“一块屏”项目，开始将网课直播引入偏远地区学校进行试点。2019年，网易公益“一块屏”在数字科技推动教育普惠上迈出了重要一步，为四川省十余个县超百所学校正式开设了网课直播班，上万学子因此受益。'}
                link={"/Artical/ScreenOfNews"}
              ></PageRightNewsList>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </>  
    )
}

export default DetailOfNews