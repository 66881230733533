import React from 'react' 
import Footer from '../../../Honor/footer'
import NewsModalPicLeft from '../../../MessageCenter/components/NewsModalPicLeft'
import NewsModalPicRight from '../../../MessageCenter/components/NewsModalPicRight'
import pic from '../../../static/volunteer1.png'
import pic2 from '../../../static/volunteer2.jpeg'
import pic3 from '../../../static/volunteer3.png'
import pic4 from '../../../static/volunteer4.png'


const Volunteer = () => {

  return (
  <div style={{position: 'relative', height: '130rem'}}>
    <div className={'aboutUsContainer'}>
      <div className="content">
        <div className="englishIntro">The Volunteer</div>
        <div className="chineseIntro">志愿者公益</div>
        <div className="projectContent">
          <NewsModalPicLeft
            Background={pic}
            title={'公益故事丨这个火热夏天，有道员工志愿者和一群特殊伙伴的一次特别”相遇”'}
            content="7月24日，网易有道员工公益计划联合“北京融爱融乐”共同举办了“快乐活动营——心智障碍者融合公益活动”。有道35名员工志愿者与心智障碍青年“益”起互动交流，共享游戏和夏天的美好。"
            link="/Artical/VolunteerOfNews1"
          ></NewsModalPicLeft>
          <NewsModalPicRight
            Background={pic2}
            title={'公益故事丨一场「科学预防网络防沉迷」之旅”'}
            content="近日，网易道德委员会办公室联合有道员工公益计划携手共同开展“青少年成长防线与科学体验”公益活动，与有道乐读和德拉科学的老师志愿者们一起来到杭州“未来警校”，为30余名青少年带来丰富多彩的公益服务。            "
            link="/Artical/VolunteerOfNews2"
          ></NewsModalPicRight>
          <NewsModalPicLeft
            Background={pic3}
            title={'公益故事 | 这个多彩夏天，有道员工和“心伙伴”一起打开艺术世界'}
            content="心智障碍者有个特别的名字，叫“心伙伴”。他们因自身原因非常缺乏与外界交流，大多数人需要家人24小时照顾，通过开展丰富多彩的公益活动，可以让公众对“心伙伴”有更多的理解与包容。这一次，我们邀请参观网易职场，一起做手工、绘画，更深入地促进“心伙伴”社会融合。"
            link="/Artical/VolunteerOfNews3"
          ></NewsModalPicLeft>
          <NewsModalPicRight
            Background={pic4}
            title={'公益故事|50名有道员工为流浪“毛孩子”营造“新家”'}
            content="0月23日，网易有道员工公益计划联合首都爱护动物协会共同举办了“关爱流浪动物公益活动”。50名有道员工来到首都爱护动物协会动物福利宣传教育中心，学习保护流浪小动物知识，并且一起为这些可爱的“小毛孩”们营造一个秋日打造“新家”。"
            link="/Artical/VolunteerOfNews4"
          ></NewsModalPicRight>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
  )
}

export default Volunteer