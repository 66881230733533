/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import './index.css'
import { scrollToAnchor } from '../../util'
import InfoCenterNews from './pages/News'
import YearsReport from './pages/yearsReport'
import AuditReport from './pages/programReport'
import Certificates from './pages/Certificates'

const MessageContainer = (props) => {
    const [hash, setTemp] = useState('')
    const { setPageState, setColor } = props

    useEffect(()=>{
        setPageState('service')
        setColor('black')
    },[])
    window.onhashchange=function(event){
        setTemp(event.newURL.split('#')[1])
        // headerNav(temp)
    }
    return (
        <>
            <div className="lineTop"></div>
            <div className="topBlank"></div>
            <nav className="aboutUsNavList">
                <a href="#/news" onClick={() => scrollToAnchor('fund')}>{hash === '/news' || hash === '' ? <span className="aboutUsTab">新闻动态</span> : <span className="aboutUsTabNoAct">新闻动态</span>}</a>
                <a href="#/annualReport" onClick={() => scrollToAnchor('community')}>{hash === '/annualReport' ? <span className="aboutUsTab">基金会年报</span> : <span className="aboutUsTabNoAct">基金会年报</span>}</a>
                <a href="#/auditReport" onClick={() => scrollToAnchor('orgnization')}>{hash === '/auditReport' ? <span className="aboutUsTab">审计报告</span> : <span className="aboutUsTabNoAct">审计报告</span>}</a>
                <a href="#/projectReport" onClick={() => scrollToAnchor('contactUs')}>{hash === '/projectReport' ? <span className="aboutUsTab">项目报告</span> : <span className="aboutUsTabNoAct">项目报告</span>}</a>
                <a href="#/certificates" onClick={() => scrollToAnchor('supportUs')}>{hash === '/certificates' ? <span className="aboutUsTab">相关证书</span> : <span className="aboutUsTabNoAct">相关证书</span>}</a>
            </nav>
            {hash === '/news' || hash === '' ? <InfoCenterNews></InfoCenterNews> : null}
            {hash === '/annualReport' ? <YearsReport></YearsReport> : null}
            {hash === '/auditReport' ? <AuditReport></AuditReport> : null}
            {hash === '/certificates' ? <Certificates></Certificates> : null}
        </>
        )
}

export default MessageContainer