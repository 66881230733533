/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react' 
import { useEffect } from 'react'
import Footer from '../../Honor/footer'
import Background from '../../static/screen1.jpg'
import PageRightNewsList from '../components/PageRightNewsList'
import './index.css'

const DisasterOfNews = (props) => {
  const {setColor} = props

  useEffect(()=>{
    setColor('black')
  }, [])
  return (
  <>
    <div className="lineTop"></div>
    <div className="topBlank"></div>
    {/* <div></div> */}
    <div style={{position: 'relative', height: '302rem'}}>
      <div className={'aboutUsContainer'}>
        <div className="content">
          <div className="englishIntro">Disaster Rescue</div>
          <div className="chineseIntro">灾害救助</div>
          <div className="lineBehindNews"></div>
          <div className='newsContentInDetailPage'>
            <div className='PageleftNewsDetail'>
                受历史罕见持续强降雨天气影响，河南多地发生严重内涝，众多学校受灾，正常教学工作被迫中断。部分学校受灾严重，校区由于泄洪，使河段决堤等原因，遭到大量雨水、河水流进学校，水位深处达1.2米，一些教室、生活用房、功能室也不同程度被淹，给后续的复课带来了很大困难。

                <div style={{backgroundImage: `url(https://shimo.youdao.com/uploader/f/DBU3FLzQ3nm27gc0.png)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', backgroundPosition: '50%'}}></div>
                大灾见大爱，大难见担当
                
                灾情发生后，网易有道一直密切关注着水灾中受影响的学生和学校，并紧急着手与受灾学校对接及爱心物资筹备工作。希望通过行动，助力河南学校的灾后重建，让校园能够尽早恢复灾前的生机和活力。
                
                从收集上来的受灾情况统计中可以发现，除了教室内座椅、书籍、玩教具等遭到雨水严重浸泡外，一些学校还出现了楼顶漏水，地基下陷，墙壁裂缝等情况。网易有道在筹措物资的同时，积极联系当地的工程队进行抢修。
                
                近日，河南灾区10所受捐学校的基础消杀、环境清洁、墙面翻新、桌椅及教学设备更新等援助工作已基本完成，并已投入使用。
                
                此次捐赠，网易有道根据不同学校的受灾情况和实际需求，在向受灾学校赠送内含有脉冲弥雾机、防护服、消毒用眼镜、口罩、消毒药剂等物资的捐赠包的基础上，还捐赠了修缮和复课所需的粉刷涂料、空调、桌椅、电脑等物资。
                
                天灾无情，有道有爱
                
                本次由网易有道联合北京网易公益基金会、中华社会救助基金会发起的“驰援有道——河南学校灾后修复公益行动”，与灾区学生携手共渡难关，为受灾孩子献上爱心，积极践行社会责任，获得了受捐学校师生的欢迎和感谢。郑州金水区蔚蓝幼儿园园长表示，今年罕见水灾发生后，学校开学面临很大的挑战。非常感谢有道对学校灾后教育重建的关注和支持，同时我们也将积极利用好有道给与的爱心捐助，全力帮助老师尽快恢复正常教学工作，并为学生们做好充分准备，排除隐患，重返美丽的校园。
                
                本次捐赠的学校包括河南省安阳市、巩义市、伊川县、郑州市金水区等10所中小学及幼儿园，分别为：河南省安阳市安阳县韩陵镇初级中学，河南省安阳市安阳县韩陵镇獐豹小学，河南省安阳市安阳县崔家桥镇杨辛庄小学，河南省安阳市汤阴县菜园镇西街学校，河南省巩义市米河镇高庙小学，河南省巩义市米河镇小里河小学，河南省安阳市安阳县崔家桥镇苏郭路小学，河南省伊川县酒后镇吕寨中心小学，河南省伊川县酒后镇路庙中心小学，河南省郑州市金水区蔚蓝幼儿园。
                
                部分学校收到捐赠物资后反馈：
                <div style={{backgroundImage: `url(https://shimo.youdao.com/uploader/f/KIli5TigV19QGhhn.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', backgroundPosition: '50%'}}></div>
                <div style={{backgroundImage: `url(https://shimo.youdao.com/uploader/f/MYAGKIkDrpCFRzCx.png)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', backgroundPosition: '50%'}}></div>

                <div style={{backgroundImage: `url(https://shimo.youdao.com/uploader/f/dsvDIWmrw1nDQqoE.png)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', marginTop: '1rem', backgroundPosition: '50%',  marginBottom: '1rem'}}></div>

                <div style={{backgroundImage: `url(https://shimo.youdao.com/uploader/f/pRy6kxlLVhQd3cMA.png)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', marginTop: '1rem', backgroundPosition: '50%',  marginBottom: '1rem'}}></div>
                
                爱心助力，为爱发声
                
                网易有道除了对学校灾后基础设施修复，重建校园外，还给予了学生更多精神上的关爱。在新学期开始，网易有道联合网易云音乐为10所学校师生共同发起来了“我是校园广播员”爱心接力赛，在网易云音乐搜索“声音治愈师”可直达活动。
                  
                参与者将以校园广播员的角色加入到爱心朗读活动中，通过声音传递治愈人心的温暖力量。并把活动里每一次发声都转化成爱的馈赠，为一起守护孩子们的读书梦想助力。参与者只需朗读素材库中的任意一条内容，例如《你是惊鸿少年》、《愿你》、《天真的预示》等，并上传成功，即完成了爱心接力。
                
                此次爱心接力活动自9月12日启动以来，获得了众多网友的积极参与，截至目前，活动已收集到上万条上传声音。同时，众多网友还在评论区为孩子们加油助力、送上祝福，希望给孩子们带来更多的爱与温暖，助力学子们圆梦未来。
            </div>
            <div className='lineMiddleInArtical'></div>
            <div className='NewsRightInArticla'>
              {/* <div className="chineseIntro">相关报道</div> */}
              <PageRightNewsList
                Background={Background}
                title={'网易一块屏'}
                content={'从2018年起，网易公益发起“一块屏”项目，开始将网课直播引入偏远地区学校进行试点。2019年，网易公益“一块屏”在数字科技推动教育普惠上迈出了重要一步，为四川省十余个县超百所学校正式开设了网课直播班，上万学子因此受益。'}
                link={"/Artical/ScreenOfNews"}
              ></PageRightNewsList>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </>  
    )
}

export default DisasterOfNews