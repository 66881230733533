/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react' 
import { useEffect } from 'react'
import Footer from '../../Honor/footer'
import Background from '../../static/screen1.jpg'
import PageRightNewsList from '../components/PageRightNewsList'
import './index.css'

const ClassOfNews = (props) => {
  const {setColor} = props

  useEffect(()=>{
    setColor('black')
  }, [])
  return (
  <>
    <div className="lineTop"></div>
    <div className="topBlank"></div>
    {/* <div></div> */}
    <div style={{position: 'relative', height: '280rem'}}>
      <div className={'aboutUsContainer'}>
        <div className="content">
          <div className="englishIntro">One Classroom</div>
          <div className="chineseIntro">网易一间教室</div>
          <div className="lineBehindNews"></div>
          <div className='newsContentInDetailPage'>
            <div className='PageleftNewsDetail'>
            日前，网易有道联合北京网易公益基金会开展的全国首家“一间教室”公益项目落地河北青龙县双山子小学。孩子们在新建成的太空主题教室，上了一节生动的“双师互动”围棋课。
            作为教育部驻青龙挂职团队引进的帮扶项目，“一间教室”通过设计主题教育空间，升级和迭代教学软硬件、提供特色优质素质类教学服务，以科技创新切实助力教育高质量发展。
            青龙曾是河北秦皇岛市唯一的国家扶贫开发重点县，教育质量同外地县区相比还相对落后。在教育部驻青龙挂职干部的牵线努力下，网易有道作为教育公司，发挥科技创新的优势，多次实地考察、师生采访、专家研讨。历时3个月时间，将“一间教室”公益项目落地青龙双山子小学。
            在刚刚过去的9月1日，全国孩子们的开学第一课上，神州14号乘组的3名宇航员在天宫空间站完成了太空授课。据网易有道“一间教室”公益项目的设计师党纤纤表示，国家科技实力和综合国力日益强大，从2021年开始，中国进入空间站时代，航天取得了举世瞩目的成就。我们希望通过太空的主题，让孩子们感受到无垠的未知世界。
            <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407181219WechatIMG501.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', backgroundPosition: '50%', height: '34rem'}}></div>
            一间教室的前后墙如同太空舱信息显示屏和舱门，教室专门设置了讲授演示区、学习区、储物和展示区等功能模块。前墙太空舱信息显示屏为教师讲授演示区，太空舱控制台集合了讲桌和多媒体终端。教室中间区域为儿童学习区，后墙舱门内嵌了磁吸板，为互动教学区和图书角。教室左侧设计为太空舱舷窗形式，舱体构件变身为展板，学生作品、教学成果可以以专题或主题形式在此陈列展示。
            <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407181408WechatIMG502.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', backgroundPosition: '50%', height: '34rem'}}></div>
            “希望孩子们怀着民族自豪感，怀着建设祖国的远大志向，探索世界，探索自己身上的无限可能。愿‘一间教室’成为孩子们走向更大世界的起点和开始。”党纤纤说。
            项目还为学校捐赠了智慧课堂必备的平板电脑、智慧大屏、以及完善的有道纵横围棋课程，以及德拉科学课、机器人小方编程课程。线上名师教学、加上线下老师指导的“双师同育”模式，能更好地确保教学质量。
            <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407184034WechatIMG503.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', backgroundPosition: '50%', height: '34rem'}}></div>
            “软件，硬件，素质教育课程，一应俱全。我们是山区里镇上的学校，教育水平有限，孩子们下课一般只能跳跳舞或者玩玩五子棋。现在，我们也专门把围棋和变成作为学校的校本课程，切实帮助孩子们提高综合素质。”双山子小学的教师张城源介绍。
            从设计之初，教室就充分考虑了智慧教室素质教育的多场景教学使用模式。通过桌椅、灯光和硬件设备的布置变化，来满足不同教学使用场景。桌子形状适合最大化的自由拼接，可两两对拼，可形成环形，可行列布局，以满足围棋课、编程课、科学课等等课程的教学组织形式。学生还可以坐在太空箱上，围绕后磁吸墙进行互动讨论，进行PBL专题课的学习。设计充分考虑了教学组织的灵活性，以适应新技术、新理念下的素质教育教学。
            <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407189393WechatIMG505.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', backgroundPosition: '50%', height: '34rem'}}></div>
            青龙满族自治县人民政府挂职副县长汪立峰在落成仪式上致辞表示，近年来，教育部积极引进社会帮扶力量，助力青龙乡村全面振兴。此次网易有道全国首家“一间教室”在双山子小学顺利落地，对于提升青龙教育信息化水平，促进青龙教育教学质量提升具有重要意义。希望学校以此为契机，将教育教学的新理念、新设备、新技术与新知识惠及更多师生。
            <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673510127641image+%284%29.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', backgroundPosition: '50%', height: '34rem'}}></div>
            作为网易旗下聚焦教育领域的战略布局，多年来，网易有道公司坚持教育公益属性，在助力乡村振兴方面不断贡献企业力量。一方面，为残疾人士免费开放职业技能培训课程，促进残疾人士自立、就业；此外，还发起“一块屏幕改变命运”项目，为西南地区初高中学校搭建双师网课平台，支持多名山区孩子考入北京大学、复旦大学、武汉大学等顶尖高校。
            网易有道党委书记、北京网易公益基金会理事长严晋表示，“一间教室”公益项目是网易有道在教育公益领域的一次全新探索和突破，我们希望通过设计主题教育空间，升级和迭代教学软硬件、提供特色优质素质类教学服务，建设打造一个系统化与模块化的友好教学空间样板，助力学校在教育信息化和素质教育等方面进行提升。
           </div>
            <div className='lineMiddleInArtical'></div>
            <div className='NewsRightInArticla'>
              {/* <div className="chineseIntro">相关报道</div> */}
              <PageRightNewsList
                Background={Background}
                title={'网易一块屏'}
                content={'从2018年起，网易公益发起“一块屏”项目，开始将网课直播引入偏远地区学校进行试点。2019年，网易公益“一块屏”在数字科技推动教育普惠上迈出了重要一步，为四川省十余个县超百所学校正式开设了网课直播班，上万学子因此受益。'}
                link={"/Artical/ScreenOfNews"}
              ></PageRightNewsList>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </>  
    )
}

export default ClassOfNews