import React from 'react' 
import Footer from '../../../Honor/footer'
import './index.css'

const FundIntroduce = () => {
  return (
    <div style={{position: 'relative', height: '77rem'}}>
      <div className={'aboutUsContainer'}>
        <div className="content">
          <div className="englishIntro">Foundation Introduction</div>
          <div className="chineseIntro">基金会介绍</div>
          <br/><br/><br/>
          <div className='introduction'></div>
          {/* <div className='contentFamily'>
          北京网易公益基金会于2017年4月26日在北京市民政注册成立，具有慈善组织资格。基金会的业务范围是资助扶贫、济困、扶老、救孤、恤病、助残等公益项目；资助自然灾害、事故灾难和公共卫生事件等突发事件造成的损害。<br/><br/>
          北京网易公益基金会依托网易及网易有道，利用科技、教育的资源优势支持教育资源匮乏地区，陆续开展了“一块屏”网易公益教育、“冠军计划”学员奖学金等公益项目；同时，基金会还致力于教育资源普惠的实现，在玉树等偏远地区开展了“有道外语阅读角”、“有道词典笔公益入校”等相关活动，为当地孩子提供优质的学习资源，助力青少年发展。除此之外，基金会不仅积极支持河南灾区重建工作，为当地受灾学校重建并迭代了有道智慧教室。在前不久，基金会还联合网易有道，正式发布了有道词典无障碍beta版本，帮助1700万视障人群轻松高效地学习英语。
          </div> */}
          {/* <div className="imageList">
            <div className="introLeftImg">
              <div className="leftIntroOfImg"></div>
              <div className="rightIntroText">
                <div className="introLeftEnglishTextInIntro">愿 景 <span style={{fontWeight: 'normal', opacity: '0.5'}}>/ Vision</span></div>
                <div className="introLeftChineseTextInIntro">让知识无阶层流动</div>
              </div>
            </div>
            <div className="introRightImg">
              <div className="rightIntroOfImg"></div>
              <div className="rightIntroText">
                <div className="introRightEnglishTextInIntro">使 命 <span style={{fontWeight: 'normal', opacity: '0.5'}}>/ Mission</span></div>
                <div className="introRightChineseTextInIntro">链接人与教育科技资源，成为有温度的公益平台</div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Footer></Footer>
    </div>
    )
}

export default FundIntroduce