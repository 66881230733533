import './App.css';
import GlideImg from './pages/GlideImg';
import NewsContainer from './pages/News';
import HonorContainer from './pages/Honor'
import PartnerContainer from './pages/Partner'
import AboutContainer from './pages/AboutUs'
import ProjectContainer from './pages/ProjectIntroduce'
import MessageContainer from './pages/MessageCenter'
import PartyContainer from './pages/PartyBuild'
import DetailOfNews from './pages/DetailPage/News'
import InternetCerifyContainer from './pages/InternetCharityPlatform'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Mousewheel, Pagination } from 'swiper';
import SwiperCore from 'swiper/core/core';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'; // Navigation module
import { Routes, Route, Link, Navigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import DisasterOfNews from './pages/DetailPage/Disaster';
import InfomationOfNews from './pages/DetailPage/Infomation';
import InfomationSecondOfNews from './pages/DetailPage/InfomationSecond';
import ScreenOfNews from './pages/DetailPage/Screen';
import LightingPlan from './pages/DetailPage/LightingPlan';
import ClassOfNews from './pages/DetailPage/Class';
import CornerOfNews from './pages/DetailPage/Corner';
import VolunteerOfNews1 from './pages/DetailPage/Volunteer';
import VolunteerOfNews2 from './pages/DetailPage/Volunteer2';
import VolunteerOfNews3 from './pages/DetailPage/Volunteer3';
import VolunteerOfNews4 from './pages/DetailPage/Volunteer4';


SwiperCore.use([Mousewheel, Pagination])
function App() {
  const [pageState, setPageState] = useState('')
  const [display, setDisplay] = useState('flex')
  const [color, setColor] = useState('black')

  useEffect(()=>{
    setTimeout(() => { if(document.getElementById('mask') !== null) document.getElementById('mask').style.display = 'none'; setDisplay('none') }, 10000)
  }, [])

  window.onhashchange=function(event){
    setColor('black')
    // headerNav(temp)
}
  
  const Home = () => {
    return ( <Swiper
      spaceBetween={0}
      slidesPerView={1}
      onSlideChange={(swiper) => {if(swiper.activeIndex !== 0) {setColor('black')} else setColor('white'); console.log(swiper)}}
      onSwiper={(swiper) => {setPageState('home'); setColor('white')}}
      mousewheel={{
        forceToAxis: true,
        thresholdDelta:30,
      }}
      Pagination={{
        dynamicMainBullets: 1,
        renderBullet: (index) => console.log(index)
      }}
      direction={'vertical'}
      initialSlide={0} // 初始化显示哪一个
      loop={false} // 是否循环
      >
        <SwiperSlide><GlideImg></GlideImg></SwiperSlide>
        <SwiperSlide><NewsContainer></NewsContainer></SwiperSlide>
        <SwiperSlide><PartnerContainer></PartnerContainer></SwiperSlide>
        <SwiperSlide><HonorContainer></HonorContainer></SwiperSlide>
      </Swiper>)
  }
  
  return (
    <div className="App">
      <div className="headerAfter"></div>
      {/* {window.location.pathname === '/home' ? <div className="maskOfHome" id='mask' style={{display: display}}> */}
        {/* <div className="heartImg"></div>
        <div className="netEaseText">北京网易公益基金会</div> */}
      {/* </div> : <></>} */}
      <header className="App-header">
        <nav className="navList">
          <Link to="home">{pageState === 'home' || pageState === '' ? <div className='navListItem'>&nbsp;&nbsp;&nbsp;•&nbsp;首页</div> :  <div className='navListItem'>&nbsp;&nbsp;&nbsp;首页</div>}</Link>
          <Link to="aboutUs">{pageState === 'aboutUs' ? <div className='navListItem' >&nbsp;&nbsp;&nbsp;•&nbsp;关于我们</div> :  <div className='navListItem'>&nbsp;&nbsp;&nbsp;关于我们</div>}</Link>
          <Link to="projectIntroduction">{pageState === 'projectIntroduction' ? <div className='navListItem' >&nbsp;&nbsp;&nbsp;•&nbsp;项目介绍</div> :  <div className='navListItem'>&nbsp;&nbsp;&nbsp;项目介绍</div>}</Link>
          <Link to="service">{pageState === 'service' ? <div className='navListItem' >&nbsp;&nbsp;&nbsp;•&nbsp;信息中心</div> :  <div className='navListItem'>&nbsp;&nbsp;&nbsp;信息中心</div>}</Link>
          <Link to="partyBuild">{pageState === 'partyBuild' ? <div className='navListItem' >&nbsp;&nbsp;&nbsp;•&nbsp;党建专栏</div> :  <div className='navListItem'>&nbsp;&nbsp;&nbsp;党建专栏</div>}</Link>
          {/* <Link to="publicFundPlatform">{pageState === 'publicFundPlatform' ? <div className='navListItem' style={{width: '10rem'}}>•&nbsp;互联网公开募捐平台</div> : <div className='navListItem' style={{width: '10rem'}}>互联网公开募捐平台</div>}</Link> */}
        </nav>
      </header>
      <Routes>
        <Route path="" element={<Navigate replace to="/home" />} />
        <Route path="home" element={Home()} />
        <Route path="aboutUs" element={<AboutContainer setPageState={setPageState} setColor={setColor}/>} />
        <Route path="projectIntroduction" element={<ProjectContainer setPageState={setPageState} setColor={setColor}/>} />
        <Route path="service" element={<MessageContainer setPageState={setPageState} setColor={setColor}/>} />
        <Route path="partyBuild" element={<PartyContainer setPageState={setPageState} setColor={setColor}/>} />
        <Route path="publicFundPlatform" element={<InternetCerifyContainer setPageState={setPageState} setColor={setColor}/>} />
        <Route path="Artical/FightEpidemic" element={<DetailOfNews setColor={setColor}></DetailOfNews>}/>
        <Route path="Artical/DisasterOfNews" element={<DisasterOfNews setColor={setColor}></DisasterOfNews>}/>
        <Route path="Artical/InformationOfNews" element={<InfomationOfNews setColor={setColor}></InfomationOfNews>}/>
        <Route path="Artical/InformationSecondOfNews" element={<InfomationSecondOfNews setColor={setColor}></InfomationSecondOfNews>}/>
        <Route path="Artical/EducationOfNews" element={<DisasterOfNews setColor={setColor}></DisasterOfNews>}/>
        <Route path="Artical/ScreenOfNews" element={<ScreenOfNews setColor={setColor}></ScreenOfNews>}/>
        <Route path="Artical/ClassOfNews" element={<ClassOfNews setColor={setColor}></ClassOfNews>}/>
        <Route path="Artical/CornerOfNews" element={<CornerOfNews setColor={setColor}></CornerOfNews>}/>
        <Route path="Artical/VolunteerOfNews1" element={<VolunteerOfNews1 setColor={setColor}></VolunteerOfNews1>}/>
        <Route path="Artical/VolunteerOfNews2" element={<VolunteerOfNews2 setColor={setColor}></VolunteerOfNews2>}/>
        <Route path="Artical/VolunteerOfNews3" element={<VolunteerOfNews3 setColor={setColor}></VolunteerOfNews3>}/>
        <Route path="Artical/VolunteerOfNews4" element={<VolunteerOfNews4 setColor={setColor}></VolunteerOfNews4>}/>
        <Route path="Artical/LightingPlan" element={<LightingPlan setColor={setColor}></LightingPlan>}/>
        {/* LightingPlan
         */}
      </Routes>
      {/* <div className="logo"><span className='FontPageLogo'></span><span className='FontPageTitle' style={{color: color}}>北京网易公益基金会</span></div>  */}
      {/* 首页图片轮播，使用glide组件 */}
      
    </div>
  );
}

export default App;
