import React from 'react' 
import Footer from '../../../Honor/footer'
import './index.css'


const Orgnization = () => {
  return (<div style={{position: 'relative', height: '90rem',}}>
  <div className={'aboutUsContainer'}>
    <div className="orgnizationContent">
      <div className="englishIntro">Foundation Introduction</div>
      <div className="chineseIntro">组织架构</div>
      <div className="imgOrgnization"></div>
    </div>
  </div>
  <Footer></Footer>
</div>)
}

export default Orgnization