/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import './index.css'

const InternetCerifyContainer = (props) => {
    const { setPageState, setColor } = props

    useEffect(()=>{
        setPageState('publicFundPlatform')
        setColor('black')
    },[])
    return (
        <>
            <div className="lineTop"></div>
            <div className='charityContent'>
                <div className="heartImg"></div>
                <div className="buildingText">正在建设中...</div>
            </div>
        </>
        )
}

export default InternetCerifyContainer