/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react' 
import { useEffect } from 'react'
import Footer from '../../Honor/footer'
import Background from '../../static/screen1.jpg'
import PageRightNewsList from '../components/PageRightNewsList'
import './index.css'

const VolunteerOfNews = (props) => {
  const {setColor} = props

  useEffect(()=>{
    setColor('black')
  }, [])
  return (
  <>
    <div className="lineTop"></div>
    <div className="topBlank"></div>
    {/* <div></div> */}
    <div style={{position: 'relative', height: '330rem'}}>
      <div className={'aboutUsContainer'}>
        <div className="content">
          <div className="englishIntro">The Volunteer</div>
          <div className="chineseIntro">志愿者公益</div>
          <div className="lineBehindNews"></div>
          <div className='newsContentInDetailPage'>
            <div className='PageleftNewsDetail'>
            <h2 style={{height:'auto',marginTop:'0.5rem'}}>公益故事 | 这个多彩夏天，有道员工和“心伙伴”一起打开艺术世界</h2>
心智障碍者有个特别的名字，叫“心伙伴”。他们因自身原因非常缺乏与外界交流，大多数人需要家人24小时照顾，通过开展丰富多彩的公益活动，可以让公众对“心伙伴”有更多的理解与包容。
一个月前，有道志愿者陪伴“心伙伴”进行游戏互动，这一次，我们邀请参观网易职场，一起做手工、绘画，更深入地促进“心伙伴”社会融合。
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407651906WechatIMG516.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 

<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407651107WechatIMG517.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
下午2点整，“心伙伴”陆续抵达网易大厅，并在签到板上选择编号区域画出“最开心的自己”，与选择同样编号的志愿者缘分配对，开启今天的“艺术之旅”。
<h2 style={{height:'auto',textAlign:'center'}}>园区游览</h2>
有道少儿美术的老师热情的开场和细致的讲解引导大家快速掌握今天的体验内容。志愿者耐心辅助“心伙伴”一起在草帽上一笔一划勾勒出多彩的图案，用粘土捏出造型各异的可爱动物。
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652413WechatIMG518.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652846WechatIMG519.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
<h2 style={{height:'auto',textAlign:'center'}}>艺术体验</h2>
下午2点整，“心伙伴”陆续抵达网易大厅，并在签到板上选择编号区域画出“最开心的自己”，与选择同样编号的志愿者缘分配对，开启今天的“艺术之旅”。
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652458WechatIMG521.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652447WechatIMG520.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
<h2 style={{height:'auto',textAlign:'center'}}>展示分享</h2>
活动尾声，“心伙伴”们和志愿者们一起围坐一圈，轮流展示了合作创作的艺术作品，并且分享今天的活动感受。绘画、手工可以帮助“心伙伴”们融入社会，艺术探索也可以帮助“心伙伴”们进行内心表达。“心伙伴”们都有着令人惊叹的创造力，他们通过创作“冰墩墩”、“太阳系”、“福寿临门”等草帽涂鸦和粘土模型，表达出了他们天马行空的想法和自己内心中多彩的艺术世界。
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407651444WechatIMG522.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652541WechatIMG523.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
<br/>他们说
<br/>“心伙伴”家长：
<br/>经常参加这样的活动相信对我们孩子们这样的群体的未来的生活，他们无论如何走入社会能走到哪一步，都是有很正向积极的影响的，希望这种活动能够持续地办下去。
<br/>美术老师加加：
<br/>这次的艺术活动很有意义，很成功，我非常惊讶于他们精神世界的宽广，想法的丰富，创作的自由。每个人都享受着创作的愉悦和成功的喜悦。希望可以有更多这样的机会让我们能再一次和他们一起座艺术活动，更加了解他们！
<br/>志愿者小陈520：
<br/>通过一下午的志愿活动，感受到“心伙伴”的内心其实都有一个独特且有趣的小宇宙。我们用多一分耐心去破解和他们沟通的密码，彼此也多了一个视角去看世界。
同时也更深刻感受到，照顾“心伙伴”的家人们是非常可敬的一群人。他们每时每刻都在付出心力，顾全“心伙伴”的身心健康，守护了小家，也携手守护了弱势群体的“大家”。感谢北京网易公益基金会的组织，让我们有机会为公益尽一份绵薄之力。
最后，希望“心伙伴”有健康的人生，有多彩的未来。
</div>
            <div className='lineMiddleInArtical'></div>
            <div className='NewsRightInArticla'>
              {/* <div className="chineseIntro">相关报道</div> */}
              <PageRightNewsList
                Background={Background}
                title={'网易一块屏'}
                content={'从2018年起，网易公益发起“一块屏”项目，开始将网课直播引入偏远地区学校进行试点。2019年，网易公益“一块屏”在数字科技推动教育普惠上迈出了重要一步，为四川省十余个县超百所学校正式开设了网课直播班，上万学子因此受益。'}
                link={"/Artical/ScreenOfNews"}
              ></PageRightNewsList>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </>  
    )
}

export default VolunteerOfNews