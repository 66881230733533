import React from 'react' 
import Footer from '../../../Honor/footer'
import { pdfjs } from 'react-pdf'
import './index.css'
import { Spin,Table } from 'antd'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const loadingStage = () => {
  return <div style ={{width: '5rem', height: '5rem'}}><Spin size="large"></Spin></div>
}

const data = [
  {name:'北京网易公益基金会审计报告',time:'2023年9月',href:'https://ydschool-video.nosdn.127.net/17163449707212023_shenji.pdf'},
  {name:'北京网易公益基金会审计报告',time:'2022年9月',href:'https://ydschool-video.nosdn.127.net/17163449708842022_shenji.pdf'},
  {name:'北京网易公益基金会审计报告',time:'2021年9月',href:'https://ydschool-video.nosdn.127.net/17163449715352021_shenji.pdf'},
  {name:'北京网易公益基金会审计报告',time:'2020年9月',href:'https://ydschool-video.nosdn.127.net/17163449711432020_shenji.pdf'},
  {name:'北京网易公益基金会审计报告',time:'2019年9月',href:'https://ydschool-video.nosdn.127.net/17163449711122019_shenji.pdf'},
  {name:'北京网易公益基金会审计报告',time:'2018年9月',href:'https://ydschool-video.nosdn.127.net/17163449706712018_shenji.pdf'},
  {name:'北京网易公益基金会审计报告',time:'2017年9月',href:'https://ydschool-video.nosdn.127.net/17163449705172017_shenji.pdf'}
];
const columns=[
  {
    title: '审计年报',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '时间',
    dataIndex: 'time',
    key: 'time',
  }
]

const AuditReport = () => {
  
  return (
  <div style={{position: 'relative', height: '92rem'}}>
    <div className={'aboutUsContainer'}>
      <div className="content">
        <div className="englishIntro">Audit Report</div>
        <div className="chineseIntro">审计报告</div>
        <Table columns={columns} dataSource={data} style={{marginTop:"20px"}} 
       onRow={record => {
        return {
          onClick: event => {window.location.href=record.href}, 
        };
      }} />
      </div>
    </div>
    <Footer></Footer>
  </div>)
}

export default AuditReport