import React from 'react' 
import Footer from '../../../Honor/footer'
import NewsModalPicLeft from '../../../MessageCenter/components/NewsModalPicLeft'
import pic from '../../../static/disease1.png'


const Epidemic = () => {

  return (
  <div style={{position: 'relative', height: '77rem'}}>
    <div className={'aboutUsContainer'}>
      <div className="content">
        <div className="englishIntro">Fight the Epidemic </div>
        <div className="chineseIntro">抗疫支持</div>
        <div className="projectContent">
          <NewsModalPicLeft
            Background={pic}
            title={'抗疫支持'}
            content="2020年初，疫情在湖北爆发，国家卫健委调配全国各地专家和医护人员，组建国家援鄂抗疫医疗队，星夜驰援湖北。为了关爱援鄂医护人员及其子女，致谢英雄及背后的“守望者”，北京网易公益基金会联合中国人口福利基金会向国家援鄂医疗队捐赠1700支智能硬件有道词典笔2.0，该批学习硬件将用于慰问国家援鄂抗疫医疗队的医护工作者，	帮助援鄂抗疫医护人员及其子女更轻松高效地学习和进步。"
            link="/Artical/FightEpidemic"
          ></NewsModalPicLeft>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
  )
}

export default Epidemic