/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react' 
import { useEffect } from 'react'
import Footer from '../../Honor/footer'
import Background from '../../static/screen1.jpg'
import PageRightNewsList from '../components/PageRightNewsList'
import './index.css'

const InfomationOfNews = (props) => {
  const {setColor} = props

  useEffect(()=>{
    setColor('black')
  }, [])
  return (
  <>
    <div className="lineTop"></div>
    <div className="topBlank"></div>
    {/* <div></div> */}
    <div style={{position: 'relative', height: '117rem'}}>
      <div className={'aboutUsContainer'}>
        <div className="content">
          <div className="englishIntro">Information Accessibility</div>
          <div className="chineseIntro">信息无障碍</div>
          <div className="lineBehindNews"></div>
          <div className='newsContentInDetailPage'>
            <div className='PageleftNewsDetail'>
            CAPA成立于2013年11月11日，是在W3C、中国工业和信息化部电信研究院的支持下，由阿里巴巴集团、腾讯、百度、微软（中国）、深圳市信息无障碍研究会共同发起的，至今已吸引国内51家公司及组织成为成员单位。
 
          “信息无障碍”指的是任何人在任何情况下可以平等、便利、无障碍地获取和使用信息。近年来，CAPA针对残障人士，以推进互联网和软件领域的信息无障碍、确保每个人能够使用互联网产品和软件产品的基本功能为宗旨，不断促进企业内外的互联网产品和软件产品符合信息无障碍技术要求。
          
          作为一家以成就学习者“高效学习”为使命的智能学习公司。网易有道依托强大的互联网AI等技术手段，围绕学习场景，打造了一系列多元化的学习产品和服务。其中，有道词典以其权威性和便捷性成为深受用户喜爱的学习工具，截至目前累计用户量已超9亿。
          
          今年下半年，在合作伙伴的协助下，网易有道开始对有道词典开展无障碍优化，通过用户研究、无障碍测试、行业标准同步、技术支持等服务构建系统的无障碍解决方案，不断扩展障碍用户使用有道词典的可能。
          
          网易有道认为，产品信息无障碍化不仅可以提升产品包容性和普适性，更能彰显企业价值和品牌温度。在“共同富裕”精神的指导下，网易有道将积极承担企业社会责任，支持残障用户平等享受科技进步带来的益处，拓展残障用户的学习边界，提高学习效率。据悉，网易有道未来会不断听取用户反馈，持续对多个产品进行无障碍设计与优化，让更多障碍用户可以利用网易有道的产品高效学习。
          
          信息无障碍产品联盟相关负责人表示：“联盟欢迎网易有道的加入，希望网易有道未来能充分发挥技术和创新优势，为障碍人群带来更多信息无障碍产品及服务，让科技助力教育公平，让人人都能享受最好的教育科技。”

              <div style={{backgroundImage: `url(https://shimo.youdao.com/uploader/f/udUTdAx4P24DKPq8.jpg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '36rem'}}></div>
            </div>
            <div className='lineMiddleInArtical'></div>
            <div className='NewsRightInArticla'>
              {/* <div className="chineseIntro">相关报道</div> */}
              <PageRightNewsList
                Background={Background}
                title={'网易一块屏'}
                content={'从2018年起，网易公益发起“一块屏”项目，开始将网课直播引入偏远地区学校进行试点。2019年，网易公益“一块屏”在数字科技推动教育普惠上迈出了重要一步，为四川省十余个县超百所学校正式开设了网课直播班，上万学子因此受益。'}
                link={"/Artical/ScreenOfNews"}
              ></PageRightNewsList>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </>  
    )
}

export default InfomationOfNews