import React from 'react' 
import Footer from '../../../Honor/footer'
import { Document, Page, pdfjs } from 'react-pdf'
import file from './static/community.pdf'
import './index.css'
import { Spin } from 'antd'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const loadingStage = () => {
  return <div style ={{width: '5rem', height: '5rem'}}><Spin size="large"></Spin></div>
}

const Community = () => {
  
  return (
  <div style={{position: 'relative', height: '902rem'}}>
    <div className={'aboutUsContainer'}>
      <div className="content">
        <div className="englishIntro">Articles of Association</div>
        <div className="chineseIntro">机构章程</div>
        <div className="docFile">
          <Document
              file={file}	//文件路径或者base64
              // onLoadProgress={loadingStage()} //成功加载文档后调用
              onLoadError={console.error} //加载失败时调用
              renderMode="canvas"	 //定义文档呈现的形式
              loading={loadingStage()} //加载时提示语句
              externalLinkTarget="_blank"	
              noData=''
            >
                {
                        new Array(11).fill('').map((item, index) => {
                            return <Page width={1000} key={index} pageNumber={index} noData='' canvasBackground='transparent' loading={loadingStage()}/>
                        })
                    }
                {/* //scale 呈现的比例 */}
          </Document>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>)
}

export default Community