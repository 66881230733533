/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react' 
import { useEffect } from 'react'
import Footer from '../../Honor/footer'
import Background from '../../static/screen1.jpg'
import PageRightNewsList from '../components/PageRightNewsList'
import './index.css'

const VolunteerOfNews = (props) => {
  const {setColor} = props

  useEffect(()=>{
    setColor('black')
  }, [])
  return (
  <>
    <div className="lineTop"></div>
    <div className="topBlank"></div>
    {/* <div></div> */}
    <div style={{position: 'relative', height: '250rem'}}>
      <div className={'aboutUsContainer'}>
        <div className="content">
          <div className="englishIntro">The Volunteer</div>
          <div className="chineseIntro">志愿者公益</div>
          <div className="lineBehindNews"></div>
          <div className='newsContentInDetailPage'>
            <div className='PageleftNewsDetail'>
              <h2 style={{height:'auto',marginTop:'0.5rem'}}>公益故事丨这个火热夏天，有道员工志愿者和一群特殊伙伴的一次特别”相遇”</h2>
              7月24日，网易有道员工公益计划联合“北京融爱融乐”共同举办了“快乐活动营——心智障碍者融合公益活动”。有道35名员工志愿者与心智障碍青年“益”起互动交流，共享游戏和夏天的美好。
            <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407651593image+%2810%29.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
            心智障碍者有个特别的名字，叫“心伙伴”。他们因自身原因非常缺乏与外界交流，大多数人需要家人24小时照顾，通过开展丰富多彩的公益活动，可以让公众对“心伙伴”有更多的理解与包容，让心智障碍者们也能更好地走出家门，融入社会。
            上午9时，志愿者抵达“Game On篮球中心”，在“融爱融乐”老师及网易有道公益团队的组织下，志愿者和“心伙伴”开始了一对一交流和热身运动。
            <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652015WechatIMG507.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
            <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652624WechatIMG508.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
            <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407651719WechatIMG509.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
            活动尾声，志愿者和“心伙伴”们形成一个大圈，合力撑起彩虹伞，协力抖动海洋球，随着海洋球被抛向高空之中，所有人的脸上都露出了灿烂的笑容……
            <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652165WechatIMG510.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
            <h2 style={{height:'auto',marginTop:'0.5rem'}}>志愿者分享</h2>
            在最后的分享环节，有道员工志愿者们回顾了活动的经历和感受。
            <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652165WechatIMG510.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
            “说实话，今天活动刚开始的时候有点不知所措，到后来慢慢融入，气氛很融洽。他们中的很多人愿意主动来跟我们分享自己生活和感受，他们的世界是很纯洁的。这次活动对我来说非常有意义。”                                                          --志愿者@小琳
            <br/>“对于心智障碍者的认知偏见、心智障碍者自身存在的沟通障碍等各类原因，仿佛是一道道厚重的幕布，将心智障碍者隔绝在社会主流视野之外。 我希望可以通过这次活动，给心智障碍青年一份陪伴和鼓励，帮助他们可以更好地融入社会环境，也期望可以通过相应的活动，让社会更多的了解、关注心智障碍者等残疾人特殊群体，逐步消除大众对他们的偏见。”--志愿者@小陈
            <br/>“每个人的世界都有不一样的色彩，每一个人都值得包容和尊重”。--志愿者@小郭
            </div>
            <div className='lineMiddleInArtical'></div>
            <div className='NewsRightInArticla'>
              {/* <div className="chineseIntro">相关报道</div> */}
              <PageRightNewsList
                Background={Background}
                title={'网易一块屏'}
                content={'从2018年起，网易公益发起“一块屏”项目，开始将网课直播引入偏远地区学校进行试点。2019年，网易公益“一块屏”在数字科技推动教育普惠上迈出了重要一步，为四川省十余个县超百所学校正式开设了网课直播班，上万学子因此受益。'}
                link={"/Artical/ScreenOfNews"}
              ></PageRightNewsList>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </>  
    )
}

export default VolunteerOfNews