import React from 'react' 
import Footer from '../../../Honor/footer'
import NewsModalPicLeft from '../../../MessageCenter/components/NewsModalPicLeft'
import pic from '../../../static/disaster1.png'

export const Disaster = () => {
  return ( <div className='detailPageContainer' style={{position: 'relative', height: '77rem'}}>
  <div className={'aboutUsContainer'}>
    <div className="content">
      <div className="englishIntro">Disaster Rescue </div>
      <div className="chineseIntro">灾害救助</div>
      <div className="projectContent">
        <NewsModalPicLeft
          Background={pic}
          title={'河南水灾捐赠'}
          content="2021年7月，受历史罕见持续强降雨天气影响，河南多地发生严重内涝，众多学校受灾，正常教学工作被迫中断。灾情发生后，网易有道一直密切关注着水灾中受影响的学生和学校，并紧急着手与受灾学校对接及爱心物资筹备工作。希望通过行动，助力河南学校的灾后重建，让校园能够尽早恢复灾前的生机和活力。"
          link="/Artical/DisasterOfNews"
        ></NewsModalPicLeft>
      </div>
    </div>
  </div>
  <Footer></Footer>
</div>)
}

export default Disaster