import React from 'react' 
import './index.css'
import Footer from '../../../Honor/footer'

const SupportUs = () => {
  return (
  <div style={{position: 'relative', height: '77rem'}}>
    <div className={'aboutUsContainer'}>
        <div className="content">
          <div className="englishIntro">Support US</div>
          <div className="chineseIntro">支持我们</div>
          <div className='supportContext'></div>
          {/* <div className='supportContext'>
            <div className="supportUsText">
              <span>
              感谢您对北京网易公益基金会的关注与信任。
              </span>
              <br/>
              <span>
              公益需要更多社会力量的支持，欢迎每一位热衷公益的伙伴
              </span>
              <br/>
              <span>
              和我们一起，用行动“让知识无阶层流动”。
              </span>
              <br/>
              <br/>
              <span style={{fontWeight: 'bold'}}>
              爱心人士与爱心企业捐赠、
              </span>
              <span style={{fontWeight: 'bold'}}>
              志愿者招募、
              </span>
              <span style={{fontWeight: 'bold'}}>
              机构合作
              </span>
              <br/>
              <span style={{fontWeight: 'bold'}}>
              均可发送邮件或拨打电话与我们进行联系。
              </span>
              <br/>
              <div className='contactEmailContent'>
                <div className='contactLeftImg'></div>
                <div>
                  <span style={{fontWeight: 'bold', fontSize: '30px'}}>gongyijiaoyu@163.com</span>
                  <br/>
                  <span style={{fontWeight: '200'}}>联系邮箱</span>
                </div>
              </div>
              <br/>
              <div className='contactPhoneContent'>
              <div className='phoneLeftImg'></div>
                <div>
                  <span style={{fontWeight: 'bold', fontSize: '30px'}}>010-82558843</span>
                  <br/>
                  <span style={{fontWeight: '200'}}>联系电话</span>
                </div>
              </div>
            </div>
            <div className='supportUsTextRight'>
              <div className='imgLeftInsupport'>
                <div className='imgLeftInsupportTop'></div>
                <div className='imgLeftInsupportBottom'>
                  <div className='imgLeftInsupportBottomLeft'></div>
                  <div className='imgLeftInsupportBottomRight'></div>
                </div>
              </div>
              <div className='imgRightInsupport'>
                <div className="imgRightInsupportTop"></div>
                <div className='imgRightInsupportBottom'></div>
              </div>
            </div>
          </div> */}
        </div>
    </div>
    <Footer></Footer>
  </div>)
}

export default SupportUs