import React from 'react' 
import Footer from '../../../Honor/footer'
import { Document, Page, pdfjs } from 'react-pdf'
import file from './static/certificates.pdf'
import './index.css'
import { Spin } from 'antd'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const loadingStage = () => {
  return <div style ={{width: '5rem', height: '5rem'}}><Spin size="large"></Spin></div>
}

const Certificates = () => {
  
  return (
  <div style={{position: 'relative', height: '92rem'}}>
    <div className={'aboutUsContainer'}>
      <div className="content">
        <div className="englishIntro">Relevant Certificates</div>
        <div className="chineseIntro">相关证书</div>
        <div className="docFile" style={{marginTop: '2rem'}}>
        <Document
              file={file}	//文件路径或者base64
              // onLoadProgress={loadingStage()} //成功加载文档后调用
              onLoadError={console.error} //加载失败时调用
              renderMode="canvas"	 //定义文档呈现的形式
              loading={loadingStage()} //加载时提示语句
              externalLinkTarget="_blank"	
              noData=''
            >
                {
                        new Array(2).fill('').map((item, index) => {
                            return <Page width={1000} key={index} pageNumber={index} noData='' canvasBackground='transparent' loading={loadingStage()}/>
                        })
                    }
          </Document>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>)
}

export default Certificates