/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import { Link } from "react-router-dom";

const NewsModalPicSmall = (props) => {
  const {date, yearMon, link, title, content} = props

  const params = link.split(':')[0]
  return (
    <div className='newsCardShort'>
          <a className="shortArticalLink" href={link} target='_blank' rel="noreferrer">
            <div className="shortArticalleft">
              <div className='shortArticalleftTopDate'>{date}</div>
              <div className='shortArticalleftBottomDate'>{yearMon}</div>
            </div>
            <div className='midLineNewsCard'></div>
            <div className="shortArticalRight">
              <div className="topArticalSpan">
                <div className="topArticalTitleInShortNews">{title}</div>
                <div className="topArticalContent">{content}</div>
              </div>
            </div>
            {params !== 'https' ? <Link className="articalLinkArrowInNewsRight" to={link}></Link> : <div className="articalLinkArrowInNewsRight"><a href={link} target='_blank' rel="noreferrer"></a></div>}
          </a>
    </div>
  )
}

export default NewsModalPicSmall