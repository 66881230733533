/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react' 
import { useEffect } from 'react'
import Footer from '../../Honor/footer'
import Background from '../../static/screen1.jpg'
import PageRightNewsList from '../components/PageRightNewsList'
import './index.css'

const VolunteerOfNews = (props) => {
  const {setColor} = props

  useEffect(()=>{
    setColor('black')
  }, [])
  return (
  <>
    <div className="lineTop"></div>
    <div className="topBlank"></div>
    {/* <div></div> */}
    <div style={{position: 'relative', height: '210rem'}}>
      <div className={'aboutUsContainer'}>
        <div className="content">
          <div className="englishIntro">The Volunteer</div>
          <div className="chineseIntro">志愿者公益</div>
          <div className="lineBehindNews"></div>
          <div className='newsContentInDetailPage'>
            <div className='PageleftNewsDetail'>
              <h2 style={{height:'auto',marginTop:'0.5rem'}}>公益故事丨一场「科学预防网络防沉迷」之旅</h2>
              近日，网易道德委员会办公室联合有道员工公益计划携手共同开展“青少年成长防线与科学体验”公益活动，与有道乐读和德拉科学的老师志愿者们一起来到杭州“未来警校”，为30余名青少年带来丰富多彩的公益服务。
              未来警校是一所帮助青少年健康成长的学校。针对青少年成长过程中遇到的各种状况，对症下药，在进行全方位教育提升的同时，帮助处在青春叛逆期的解决网瘾等心理健康问题。
              <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407656488WechatIMG511.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
              本次公益课活动分为两节，分别是有道乐读石蕊博士自研的《青少年成长的第一道防线》以及德拉科学的老师们带来的《科学实验大挑战》。
              <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652430WechatIMG513.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
              <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652371WechatIMG512.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
              在本次活动中，有道的老师们从文化、思维逻辑与科学技术等角度，给孩子们带来一些平时难体验、难听到的内容，现场的互动场面热闹非凡，孩子和家长们的眼睛里都是透露出满满惊喜与期待。
              <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652128WechatIMG514.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 

              希望这次课程，能让孩子和家长们都获得一定拓宽兴趣的启发，让孩子们更能主动的去探索自己的兴趣。
              <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407651325WechatIMG515.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
              网易有道员工公益计划为网易有道联合北京网易公益基金会发起，旨在弘扬有道公益文化，鼓励员工参与志愿服务，体现有道人共同参与公益的精神。未来，网易有道员工公益计划将组织更多的公益活动，为有需要的群体提供支持。
              </div>
            <div className='lineMiddleInArtical'></div>
            <div className='NewsRightInArticla'>
              {/* <div className="chineseIntro">相关报道</div> */}
              <PageRightNewsList
                Background={Background}
                title={'网易一块屏'}
                content={'从2018年起，网易公益发起“一块屏”项目，开始将网课直播引入偏远地区学校进行试点。2019年，网易公益“一块屏”在数字科技推动教育普惠上迈出了重要一步，为四川省十余个县超百所学校正式开设了网课直播班，上万学子因此受益。'}
                link={"/Artical/ScreenOfNews"}
              ></PageRightNewsList>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </>  
    )
}

export default VolunteerOfNews