import React from 'react' 
import Footer from '../../../Honor/footer'
import NewsModalPicLeft from '../../../MessageCenter/components/NewsModalPicLeft'
import NewsModalPicRight from '../../../MessageCenter/components/NewsModalPicRight'
import pic from '../../../static/screen1.png'
import pic1 from '../../../static/cornor1.jpg'
import pic2 from '../../../static/img22.png'


const Screen = () => {

  return (
  <div style={{position: 'relative', height: '110rem',overflow:'hidden'}}>
    <div className={'aboutUsContainer'}>
      <div className="content">
        <div className="englishIntro">Education Popularization</div>
        <div className="chineseIntro">教育普惠</div>
        <div className="projectContent">
          <NewsModalPicLeft
            Background={pic}
            title={'网易一块屏'}
            content="从2018年起，网易公益发起“一块屏”项目，开始将网课直播引入偏远地区学校进行试点。2019年，网易公益“一块屏”在数字科技推动教育普惠上迈出了重要一步，为四川省十余个县超百所学校正式开设了网课直播班，上万学子因此受益。"
            link="/Artical/ScreenOfNews"
          ></NewsModalPicLeft>
           <NewsModalPicRight
            Background={pic1}
            title={'有道外语阅读角'}
            content="有道外语阅读角是网易有道、北京网易公益基金会联合发起的公益项目，通过捐赠智能学习硬件、优选中英文书籍以及提供活动资金，助力困境儿童提升英语学习兴趣和获得更多优质阅读资源。截止目前，项目已在全国17省市建设40个项目点，近6万人次困境儿童受益。"
            link="/Artical/CornerOfNews"
          ></NewsModalPicRight>
          <NewsModalPicLeft
            Background={pic2}
            title={'微光计划'}
            content="“微光计划”是国内公益行业内首个为残疾人群体提供“技能培训+就业推荐”的一站式公益行动，通过课程学习和技能培训，切实帮助残疾人就业，希望从根本上解决残疾人就业难的问题。“微光计划”联动企业内力量集聚优质课程为不同文化程度、不同残障情况的残疾人提供线上教学以及一些个性化辅导服务。项目通过开设播音、插画、IT类课程为求职残疾人提供了系统培训和教辅服务，也为他们寻找就业机会提供了力所能及的帮助。自“微光计划”上线以来，已有超过7500名学员陆续开始学习IT和副业课程，超过3000位残疾人在本次计划中受益，其中12人已通过培训顺利就业。"
            link="/Artical/LightingPlan"
          ></NewsModalPicLeft>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
  )
}

export default Screen