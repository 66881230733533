import React from 'react' 
import Footer from '../../../Honor/footer'
import './index.css'
import pic from '../../../static/image3.jpg'
import NewsModalPicLeft from '../../components/NewsModalPicLeft';
import NewsModalPicSmall from '../../components/NewsModalSmall'
// import { Button } from 'antd'

const InfoCenterNews = () => {
  return (
  <div style={{position: 'relative', height: '450rem',overflow:'hidden'}}>
    <div className={'aboutUsContainer'}>
      <div className="content">
        <div className="englishIntro">News</div>
        <div className="chineseIntro">新闻动态</div>
        <NewsModalPicLeft
            Background={pic}
            title={'公益故事 | 聆听有道外语阅读角传来的朗读声'}
            content="2021年末，在有道外语阅读角公益项目运营1周年之际，网易有道联合北京网易公益基金会举办了一场别样的线上英语朗读比赛。本次比赛共收到了全国10省市20余有道外语阅读角合作机构162名小朋友的作品，从北京城乡社区，到四川阿坝高原少数民族学校，来自天南海北的小朋友们都精心准备、积极的展示自己。虽然作品水平参差不齐，有的发音也不是非常标准，但他们声音洪亮，情感充沛且自信，充分展现出对英语学习的热爱和执着。"
            link="https://mp.weixin.qq.com/s/IbPbuH-8mG5YKejizzbJVw"
          ></NewsModalPicLeft>
          <NewsModalPicSmall
            date={31}
            yearMon={'2021/12'}
            title={'基金会年度特辑 | 2021我们全力以赴的10个瞬间'}
            content={'回望过去一年，北京网易公益基金会秉承初心，在不断深耕教育公益，运作升级“一块屏”网易公益教育项目的同时，积极联动多方资源，围绕“建党百年、乡村振兴、抗疫救灾、共同富裕”等重点方向开展了有道外语阅读角“点亮长征路——有道词典笔致敬建党100周年”公益活动、“河南学校灾后修复公益行动”、“阳光有道公益课堂”、“信息无障碍”等公益项目，覆盖全国20余个省市自治区，近30万学生受益。'}
            link="https://mp.weixin.qq.com/s/kPfS-2zval-T7UWIbw9YYQ"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={19}
            yearMon={'2022/12'}
            title={'获大奖! “微光计划”入选《CCF 2022技术公益年度案例集》'}
            content={'《CCF 2022技术公益年度案例集》于CNCC 2022大会期间正式发布，案例集汇集了56家企业的优秀技术公益案例，覆盖无障碍、教育、环保以及健康等领域，助力社会治理，实现社会担当。在本年度的评选中，网易有道的“微光计划”凭借在公益领域的突出贡献，经CCF公益工作委员会专家组认可，成功入选《CCF 2022技术公益年度案例集》。'}
            link="https://mp.weixin.qq.com/s/K317gOnU4GUVaglVbwzc8A"
          ></NewsModalPicSmall>

          <NewsModalPicSmall
            date={13}
            yearMon={'2022/12'}
            title={'这个寒冷的冬天，让有道的温暖抵达远方'}
            content={'9月16日，网易有道公益项目全国首家“一间教室”正式落地青龙满族自治县双山子小学，项目通过设计主题教育空间、升级和迭代教学软硬件等方式，致力于为偏远地区的学校提供优质的素质类教学服务。自“一间教室”项目落地以来，受助学校的教育信息化水平得到了切实有效的提升。为了持续拓展“一间教室”的教学使用场景，使项目更好地运行，网易有道结合当地学生对英语学习和素质类课程的强烈诉求。'}
            link="https://mp.weixin.qq.com/s/5PKJPsEDmru6RC9vfYTW9A            "
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={5}
            yearMon={'2022/12'}
            title={'国际志愿者日丨感谢所有为爱前行的志愿者'}
            content={'今年是第37个国际志愿者日，1985年12月17日，第四十届联合国大会通过决议，从1986年起，每年的12月5日为国际促进经济和发展志愿人员日”。希望通过庆祝活动唤起更多人的志愿者身份从事社会发展和经济建设事业。'}
            link="https://mp.weixin.qq.com/s/K1l7ynubWaGuC8F4NPoeGA"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={3}
            yearMon={'2022/12'}
            title={'再获殊荣！微光计划荣获教育部“能者为师”推介'}
            content={'2022年11月24日，教育部职业教育与成人教育司公示了“微光计划”入选“能者为师”典型案例，向社会公众展示了有道云课堂助力全民终身学习、履行社会责任的一系列成果。'}
            link="https://mp.weixin.qq.com/s/5UDb4LGV9iQgA7FaCZRcgg"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={29}
            yearMon={'2022/12'}
            title={'微光计划协办2022“华途杯”视障者语言艺术大会，快来报名吧！'}
            content={'为寻找和挖掘优秀的有声语言艺术人才，展现视障群体热爱生活，促进视障者语言艺术爱好者的发展与多元就业，落实二十大全面发展残疾人事业和共同富裕的目标要求，开展由中国盲人协会指导，深圳市盲人协会，广州华途科技有限公司、北京心智互动科技有限公司发起，联合广东、北京、云南、广西、黑龙江、辽宁、吉林、浙江、河南、江苏、湖北、等各省级盲人协会共同举办的“心随声动！'}
            link="https://mp.weixin.qq.com/s/BLhgcoh5MwnW0-lgolvj2A"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={11}
            yearMon={'2022/11'}
            title={'网易有道公益蝉联2022年度最佳责任企业品牌奖'}
            content={'2022年11月11日，网易有道再次蝉联企业社会责任领域奖项：由CSR中国教育榜颁发的“2022年 CSR CHINA TOP100年度最佳责任企业品牌”。'}
            link="https://mp.weixin.qq.com/s/ynuxDWaRa_S5sN2IMUhz3w"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={26}
            yearMon={'2022/10'}
            title={'公益故事|50名有道员工为流浪“毛孩子”营造“新家”'}
            content={'10月23日，网易有道员工公益计划联合首都爱护动物协会共同举办了“关爱流浪动物公益活动”。50名有道员工来到首都爱护动物协会动物福利宣传教育中心，学习保护流浪小动物知识，并且一起为这些可爱的“小毛孩”们营造一个秋日打造“新家”。'}
            link="https://mp.weixin.qq.com/s/WtJeFxq2IlLCIuaft__PWQ"
          ></NewsModalPicSmall>

          <NewsModalPicSmall
            date={23}
            yearMon={'2022/9'}
            title={'这次，我们为乡村小学建了个“太空教室”！'}
            content={'日前，网易有道联合北京网易公益基金会开展的全国首家“一间教室”公益项目落地河北青龙县双山子小学。孩子们在新建成的太空主题教室，上了一节生动的“双师互动”围棋课。作为教育部驻青龙挂职团队引进的帮扶项目，“一间教室”通过设计主题教育空间，升级和迭代教学软硬件、提供特色优质素质类教学服务，以科技创新切实助力教育高质量发展。'}
            link="https://mp.weixin.qq.com/s/UPDbtBTGqhlKQC7CRUjoCw"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={9}
            yearMon={'2022/9'}
            title={'有道员工公益，等你一起拥抱“爱”！'}
            content={'在过去的9个月中，网易有道一共举办了4场志愿活动。一共有一百多名员工伙伴加入了我们，影响辐射范围达千余人次。“科技赋能教育普惠”是网易有道的初心和使命。网易有道员工用实际行动践行了这一点，希望用自己的力量在帮扶弱势群体等方面发挥作用。'}
            link="https://mp.weixin.qq.com/s/RwxYjHXPkgmNdrF_2IieXA"
          ></NewsModalPicSmall>
           <NewsModalPicSmall
            date={6}
            yearMon={'2022/9'}
            title={'网易驰援四川泸定，完成抗震救灾首次捐款'}
            content={'9月5日，四川省甘孜州泸定县发生6.8级地震，多地灾情严重，牵动化万人心。网易公益快定，向甘孜泸定地震灾区首期捐赠100万元现金及价值50万元的应急物资，用于支持当地救灾防疫、百姓防寒取暖等工作。'}
            link="https://mp.weixin.qq.com/s/wrngokUEvqcHAJVzspNy3g"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={31}
            yearMon={'2022/8'}
            title={'公益故事 | 这个多彩夏天，有道员工和“心伙伴”一起打开艺术世界'}
            content={'心智障碍者有个特别的名字，叫“心伙伴”。他们因自身原因非常缺乏与外界交流，大多数人需要家人24小时照顾，通过开展丰富多彩的公益活动，可以让公众对“心伙伴”有更多的理解与包容。一个月前，有道志愿者陪伴“心伙伴”进行游戏互动，这一次，我们邀请参观网易职场，一起做手工、绘画，更深入地促进“心伙伴”社会融合。'}
            link="https://mp.weixin.qq.com/s/QJ6MqomUmLDqOAk18x0MVQ"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={15}
            yearMon={'2022/8'}
            title={'公益故事丨一场「科学预防网络防沉迷」之旅'}
            content={'近日，网易道德委员会办公室联合有道员工公益计划携手共同开展“青少年成长防线与科学体验”公益活动，与有道乐读和德拉科学的老师志愿者们一起来到杭州“未来警校”，为30余名青少年带来丰富多彩的公益服务。'}
            link="https://mp.weixin.qq.com/s/ckQnFiK-36EyPeSkeJUVUg"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={1}
            yearMon={'2022/8'}
            title={'再次获奖! 微光计划荣获2022年度《南方周末》‘‘杰出责任传播案例’’'}
            content={'2022年7月28、29日，由南方周末主办的第十四届“中国企业社会责任年会”在广州举行，这是中国企业社会责任领域规格最高、最受业界瞩目的盛会之一，旨在探讨社会和谐构建进程中有责任担当的组织或个人所应遵循的道路，倡扬社会责任概念，推广社会责任精神，树立年度责任标杆。网易有道的”微光计划”凭借在社会责任事业的突出成绩荣获“年度杰出责任传播案例”。'}
            link="https://mp.weixin.qq.com/s/Kxysd7S5FCOiyK6MjEnXNQ"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={25}
            yearMon={'2022/7'}
            title={'公益故事丨这个火热夏天，有道员工志愿者和一群特殊伙伴的一次特别”相遇”'}
            content={'7月24日，网易有道员工公益计划联合“北京融爱融乐”共同举办了“快乐活动营——心智障碍者融合公益活动”。有道35名员工志愿者与心智障碍青年“益”起互动交流，共享游戏和夏天的美好.'}
            link="https://mp.weixin.qq.com/s/g53GQNKwrUNJV4y_fUXT3A"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={6}
            yearMon={'2022/7'}
            title={'60000人次！有道外语阅读角助力这些山区孩子梦想发光'}
            content={'自2020年8月起，网易有道联合北京网易公益基金会发起有道外语阅读角公益项目。捐赠智能学习硬件、优选中英文书籍以及活动资金，助力困境儿童提升英语学习兴趣和获得更多优质阅读资源。经过两年时间，40个有道外语阅读角在全国17省2直辖市2自治区，共21个城市落地开花，不论是在革命老区学校，还是城乡边缘社区，都有孩子们使用有道词典笔阅读书籍、老师带领学生开展英语兴趣学习活动的画面。'}
            link="https://mp.weixin.qq.com/s/347UhtALQK_S1aN9zLGCvg"
          ></NewsModalPicSmall>

          <NewsModalPicSmall
            date={17}
            yearMon={'2022/6'}
            title={'网易有道参加科技无障碍大会，“微光计划”学员作品亮相获赞 ！'}
            content={'6月15日，第四届科技无障碍发展大会圆桌论坛在线上举行，网易有道作为企业代表受邀参与论坛。网易有道成⼈教育事业部总经理冯强向与会嘉宾展示了今年网易有道与微软联合发起的“微光计划”学员的插画作品，分享科技创新企业在助力信息无障碍方面的探索实践。'}
            link="https://mp.weixin.qq.com/s/OwfuYJrX4NI9ttOj8w2FJA"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={6}
            yearMon={'2022/6'}
            title={'全国爱眼日，网易有道“微光计划”加入盲人多元就业系列培训'}
            content={'为贯彻国务院办公厅关于《促进残疾人就业三年行动方案（2022—2024年）》（国办发20226号）和中国残联办公厅《关于印发残疾人组织助残就业项目推广方案的通知》等文件精神，进一步推进残疾人就业工作，保障残疾人民生，促进残疾人共同富裕，中国盲协在今年全国助残日期间启动了“心光绽放”助盲就业项目。2022年至2024年，中国盲协将在中国残联支持下，汇聚社会公益资源，带动各级盲协共同组织开展系列助盲就业活动。'}
            link="https://mp.weixin.qq.com/s/PmguBmh3VX8zotpAH24VFQ"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={23}
            yearMon={'2022/5'}
            title={'一张图带你正确打开网易有道首份社会责任报告'}
            content={'今天，网易有道发布了2020-2021年企业社会责任报告，这也是网易有道成立以来的第一份企业社会责任报告。'}
            link="https://mp.weixin.qq.com/s/HU75ye1iVfibbLb_ZtXbiQ"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={15}
            yearMon={'2022/5'}
            title={'全国助残日，一起听见微光'}
            content={'从1991年开始，每年5月的第3个星期日是全国助残日。今年3月，网易云课堂发起教育助残公益行动“微光计划”，在微光学员的学习过程中，我们不断收获感动和惊喜。助残日是为了让更多人关注到残疾人这个群体，但身处其中的微光学员们，也有满满的爱意想要分享。这世界最美好的事，莫过于爱在循环，善良被传递。'}
            link="https://mp.weixin.qq.com/s/iZJZT-mg5gF5114R32duaQ"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={13}
            yearMon={'2022/5'}
            title={'阅读让生活多彩，关爱让eye发光'}
            content={'2022年5月10日，北京网易公益基金会携“云南白药”走进本次公益捐赠活动第二站，陕西省吴起县第二小学，开展公益捐赠活动，为在校学生和老师送去云南白药泰邦视疲劳眼罩、防蓝光眼镜、护眼灯与各类学习用品，支持有道外语阅读角公益活动及学生们的日常学习，帮助吴起县第二小学的孩子们通过阅读看到更大的世界，在书本里找到更多的色彩，用书本为孩子们架起一座通往外面世界的桥梁。'}
            link="https://mp.weixin.qq.com/s/Rjp-mQKhqpJS4erUj1_AVQ"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={29}
            yearMon={'2022/4'}
            title={'世界读书日，关爱让eye发光'}
            content={'自2020年8月起，网易有道联合北京网易公益基金会发起有道外语阅读角公益项目。捐赠智能学习硬件、优选中英文书籍以及活动资金，助力困境儿童提升英语学习兴趣和获得更多优质阅读资源。经过两年时间，40个有道外语阅读角在全国17省2直辖市2自治区，共21个城市落地开花，不论是在革命老区学校，还是城乡边缘社区，都有孩子们使用有道词典笔阅读书籍、老师带领学生开展英语兴趣学习活动的画面。'}
            link="https://mp.weixin.qq.com/s/EBfbjYaikLbUcnJDAX3wbA"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={23}
            yearMon={'2022/4'}
            title={'世界读书日丨一场跨越千里的线上公益读书会'}
            content={'4月23日，网易有道员工公益计划举办了“世界读书日，益起来读书吧”公益活动。网易有道30名员工志愿者与来自四川阿坝州红原县刷经寺镇小学、陕西延安市吴起县第二小学的小朋友们进行线上读书分享和互动，并用明信片为小朋友们写下成长祝福，陪伴小朋友们一起阅读、“益”起成长。'}
            link="https://mp.weixin.qq.com/s/MQnmqXOEidFCfsgfl8W5UA"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={21}
            yearMon={'2022/3'}
            title={'对话冬残奥首金王蒙：残疾人找工作太难了'}
            content={'日前，北京2022冬残奥会落下帷幕。比赛结束后，这些运动员退役后的生活和就业问题，同样值得关注。残奥会期间，网易云课堂团队在北京的一家酒店里与中国冬残奥历史上首枚金牌获得者、轮椅冰壶运动员王蒙见面，当时她正在房间看电视，电视上是冬残奥会的比赛直播。今年冬残奥会，她没能进入轮椅冰壶国家队的选拔名单中，但却以另外一种形式——采集“互助之火”的火种，传递奥运圣火.'}
            link="https://mp.weixin.qq.com/s/Cw1SHzyftATiPHwMXvobyw"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={14}
            yearMon={'2022/3'}
            title={'微光计划公益行动上线——开启“春天的事业”'}
            content={'3月13日，北京2022年冬残奥会落下帷幕。赛场上，运动员让所有人看见，他们不受身体束缚的灵魂。生活中，他们也在各行各业里，努力让自己闪光。作为一个在线职业教育平台，网易云课堂始终关注弱势群体的就业问题。2019年，我们开始为残疾人提供灵活办公的工作机会。2021年，我们一共向他们开放将近1000个工作岗位。2022年，我们希望不仅要授人以鱼，还要授人以渔。'}
            link="https://mp.weixin.qq.com/s/UKFeBQ_nl6djr38FwlLc3w"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={14}
            yearMon={'2022/01'}
            title={'网易有道连续三年荣膺 “2021互联网企业社会责任”奖'}
            content={'1月14日，第十一届公益节暨企业社会责任嘉年华在上海举行。经过评委会的评估认定，网易有道将社会责任纳入企业的经营和发展战略，建立了较完善的企业社会责任体系，因此获得由公益节评委会颁布的“2021互联网企业社会责任”奖项。'}
            link="https://mp.weixin.qq.com/s/DQMzbKNoyj0qYOYRE9wSGA"
          ></NewsModalPicSmall>
          <NewsModalPicSmall
            date={'07'}
            yearMon={'2022/01'}
            title={'公益故事 | 乡村艺术教育项目：用孩子的眼睛发现家乡之美'}
            content={'2019-2021年，北京网易公益基金会与公益伙伴在会宁、富宁与雷山三县落地了网易“一块屏”1.0——乡村艺术教育项目。三年来，我们开发了近百节艺术课程，培训赋能超过400名优秀种子教师，并完成8000名学生的艺术教学测评。在友成企业家扶贫基金会益教室和有道少儿美术的支持下，在2021年向近万名来自会宁、富宁、雷山与有道精品课的孩子们发起“眼睛—发现家乡之美”主题儿童艺术作品征集活动。'}
            link="https://mp.weixin.qq.com/s/pyWNJMi6z1nKuzKXo8jRaQ"
          ></NewsModalPicSmall>

          <NewsModalPicSmall
            date={'07'}
            yearMon={'2022/01'}
            title={'联合发布 |《北京基金会品牌发展报告》'}
            content={'网易公益教育项目充分运用网易和社会优质资源，致力于打造更完整、更有效、更有科技含量的线上教育帮扶平台。易公益教育与友成企业家扶贫基金会、成都林荫公益服务中心等机构合'}
            link="https://mp.weixin.qq.com/s/59wfQnjmALtYH0PIuMwsCg"
          ></NewsModalPicSmall>
          {/* <Button className='bottomMoreButton' style={{fontSize: '10px'}}><span style={{fontSize: '10px'}}>查看更多 +</span></Button> */}
      </div>
    </div>
    <Footer></Footer>
  </div>
    )
}

export default InfoCenterNews