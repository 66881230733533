/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react' 
import { useEffect } from 'react'
import Footer from '../../Honor/footer'
import Background from '../../static/screen1.jpg'
import PageRightNewsList from '../components/PageRightNewsList'
import './index.css'

const ScreenOfNews = (props) => {
  const {setColor} = props

  useEffect(()=>{
    setColor('black')
  }, [])
  return (
  <>
    <div className="lineTop"></div>
    <div className="topBlank"></div>
    {/* <div></div> */}
    <div style={{position: 'relative', height: '450rem'}}>
      <div className={'aboutUsContainer'}>
        <div className="content">
          <div className="englishIntro">One Screen</div>
          <div className="chineseIntro">网易一块屏</div>
          <div className="lineBehindNews"></div>
          <div className='newsContentInDetailPage'>
            <div className='PageleftNewsDetail'>
              <h2 style={{height:'auto'}}>一个山区县中的突围：靠什么改变命运</h2>
              高考分数真正公布，要比预告的查分时间来得更早一些。
              2022年6月23日晚，四川省泸州市古蔺县蔺阳中学高三毕业班的一众老师，聚在年级办公室里焦急等待一个答案。他们围在屏幕前，盯着每个毕业生名字下出现的分数。
              屏幕里出现了罗婷的名字。“632分！相当不错，可以冲一冲北大了！”班主任孙燎迫不及待拿起手机，给罗婷拨了过去。那一天，孙燎拨通了很多学生的电话。
              与往年相比，2022年蔺阳中学的高考成绩相当亮眼：过本科线将近1000人，重本线以上173人，超额完成当年的高考任务。其中学校的“一块屏”项目网课公益班，重本上线率接近96%。除了罗婷，另外两名同学也通过国家专项计划报考了北大，其他同学的第一志愿中，也可见武大、同济、国防科技大等学校的身影。
              这是蔺阳中学多年之后的“高光时刻”。无论从生源还是所处环境，蔺阳中学都只是中国难以计数的“县中”里平平无奇的一员——它坐落于山脉和高原之间、川滇黔三省的交界处，所处的古蔺县总与“偏远”“有待发展”紧密相连；即便在古蔺县内，它往往也不是高分考生的第一选择。它还不得不与古蔺县排在第一的高中以及泸州的四所国家级示范性高中竞争。
              一个与当地教学质量相关的历史数据，大概可以证明今年的成绩有多“亮眼”：2009年古蔺县全县应届生一共考取了6个重点本科，全部出在当时生源略差的蔺阳中学，到了2022年，这所中学重本数字变成了173人。
              在蔺阳中学副校长肖继雄看来，这与学校三年前引入的成都七中的网课资源密不可分。这份联系缘起于2018年底的一篇深度报道：以云南禄劝一中为代表的248所偏远县中，因为使用成都七中的网课资源，在16年里走出了近百名清北生。
              这个故事引发了一场社会大讨论，也打动了很多人，包括网易CEO丁磊。他决定拿出1个亿支持更多学校落地这种异地同堂的网课模式。随后，四川、湖南、甘肃、重庆等地的偏远地区的100所初高中引入网易公益“一块屏”，覆盖数万名学生。而蔺阳中学是其中的一个缩影，在许多几乎只被当地人知晓的远端郊县，始终有人渴望改变。三年前那块“可能改变命运”的屏幕，如今有了新的回响。
                <h2 style={{marginTop:'0.5rem',height:'auto'}}>县中抢生源</h2>
                将时针拨回2019年夏天。那时，来自镇中学的罗婷刚得知自己拿到了全古蔺县中考第一名。这让她感觉惊喜，她上的是一所普通的村镇初中，即使到了初三也没有分重点班，班上一半学生在初中毕业后就外出打工了。
                罗婷的中考成绩比第二名高出了几十分。成绩出来后，她像县里其他优等生一样面临古蔺县和泸州市不同学校的“争取”：有的学校直接表示学杂费全免，还许诺5000元助学金。
                过去十年间，当地的城乡教育差距在进一步加大。蔺阳中学副校长肖继雄称，他们一直在面临省、市一级学校对县中师资和生源的抽取，同时还要拼命保住竞争力。这种竞争环境下，县中格外看重高分学生对本校的带动作用：由上一届的高分考生招到好生源，再出新的高分吸引优质生源，最终形成一个良性循环。
                但是像蔺阳中学这样，在一个县里都算不得第一名的高中，与它的竞争者们相比，无论从校园环境、生源还是经济实力，实在难言有什么优势。当一个县大部分成绩优秀的学生都被其他学校招走后，留下来的往往是一些来自当地农村、家庭条件一般、学习基础也较薄弱的孩子。
                “前些年学校能招的孩子，都是农村里面家庭比较困难的，或者是老师自己一些亲戚好友的孩子，优生不可能主动选择我们”，肖继雄说。这一群能上高中但基础薄弱的孩子，多为爷爷奶奶带大的农村留守儿童，对他们的家庭来说，考上一个本科院校，就是他们最大的愿望。
                <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673406266360image+%284%29.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '0.5rem', height: '28rem'}}></div>
                在肖继雄看来，这所高中的学生最欠缺的可能还不是基础教育，而是一种自信，即便是考了全县第一的罗婷，也缺乏这种自信。很长一段时间里，罗婷都想不通自己怎么就考了全县第一，而这个光环在高中三年间，也给她带来了沉重的负担。
                <h2 style={{marginTop:'0.5rem',height:'auto'}}>从网课里突围</h2>
                2012年，国家开始实施高考招生国家专项计划，这意味着包括古蔺在内的集中连片特殊困难县、国家级扶贫开发重点县以及新疆南疆四地州的学生们，有机会通过优惠政策走入国内顶尖学府，接受更好的教育。
                肖继雄不讳言蔺阳中学的学生因此得到的政策倾斜，但与到过城市、接受过城市学校教育的孩子相比，古蔺那些成长在农村、被祖辈带大的留守儿童想要跨过名校的门槛，依然艰难。
                “这个政策属于我们，我们就应该好好地把古蔺、叙永（泸州市另外一个县）这些孩子教好、把政策用好，不只是为了冲刺清北，我们凭本事能考上多少就考上多少，让原来考二本的上一本，原来考一本的上名校。”
                因此在鸿沟之下，蔺阳中学非常看重每一个可能提升孩子们成绩和分数的机会。
                早在2005年，这所中学曾连续四年投入人力和财力，以每年数十万元的投入，尝试扭转学校并不理想的高考成绩。王光佑记得，当时学校的财政非常困难，一方面为了培养老师，另一方面为了提升学生成绩，“破釜沉舟”之下，学校通过学习平台引入了成都七中的网课。
                王光佑是四川省中小学第一个语文正高级教师，也是蔺阳中学最早一批使用屏幕教学的人。他回忆，自己17年前曾前往成都七中参加了平台机构组织的培训，但网课应当如何真正开展，他依然摸不着头脑。
                <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673406416826image+%285%29.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '0.5rem', marginBottom: '0.5rem', height: '28rem'}}></div>
                7年前刚“触网”时，网课采用同步直播的形式，即成都七中的老师在课堂那边说什么，蔺阳中学的学生们就在教室中学什么。“刚开始上网课的时候，老师们非常痛苦，因为同步直播，我们对那边的老师要讲什么，心里没底，也很迷茫。”王光佑说，当时一些经验丰富或者对课堂教学进度把握得比较好的老师，会一边上课、一边给同学们做一些点拨，有的老师还会在晚自习期间给同学做辅导，但这样的节奏对授课老师提出了更高的挑战——首先要有积极的态度和更为丰富的精力。三年的物理课，学校走马灯式地换了几个老师。
                这样的直播授课模式在争议中持续了两年，2007年秋天，最初那批学生升到高三后，一些老师选择直接关掉屏幕，但2008年春天高考的冲刺阶段，一些学生又开始强烈要求重新开网课。
                但即便如此，直播网课落地的四年，也给学校带来了非常瞩目的成绩。王光佑带的2009届网课班毕业生，直接创造了蔺阳中学那次进入校史的高考成绩。任课老师们在“痛苦”地不断学习、调整教学方法和教学知识中，也得到了长足进步。后来，6位任课老师有5位都成为了特级或高级教师，直到2010年，因经济原因，学校停止了网课教学。
                2019年8月，网易公益在全国多所分属不同脱贫县及艰苦地区的学校内，创建网络公益班。这些中学每年平均高考一本率在10%-20%。再次接触这块“可能改变命运”的屏幕时，蔺阳中学踊跃报名，以王光佑老师为代表的骨干教师，做出了比十四年前更谨慎的研讨和选择，尽管过去在屏幕使用上存在许多主客观困难，他们仍想紧紧抓住这次机会“突围”。
                <h2 style={{marginTop:'0.5rem',height:'auto'}}>水土不服</h2>
                2019年9月，新一届高一学生入学，“一块屏”项目在包括蔺阳中学在内的多所学校落地。一个网课班里最常见的场景是，讲台前面是一块显示影像的屏幕，教室后排坐着的不仅是认真听讲的学生，还有奋笔疾书的老师。
                2022年毕业的马街中学直播班学生徐梦兰和很多同学一样，3年前因这块屏幕决定留在马街中学。但开学后，她很快发现，自己和周围同学在多门课上，都难跟上屏幕里的教学进度，也难适应直播的教学模式，英语课上，屏幕对面的老师在全英文教学，她却几乎一句都听不懂。
                “一开始上这些课，有一种大开眼界的感觉，但那些课程讲的东西太多太深奥了。”徐梦兰说她自己直到高二才逐渐适应直播上课的节奏，而她身边有一位同学在高一时，因为跟不上节奏，直接选择离开直播班。
                身为地理老师的王泗春认为，这一定程度还是与成都和泸州不同上课进度、马街中学生源学业基础不及成都七中有关。高二之后，泸州市的教学范围与成都市的教学范围变得不同，马街中学的学生既要在课堂上适应成都的进度，也要花时间兼顾泸州的考点和教学范围，由于学生基础不同，成都七中的教学进度往往又比马街中学快，老师即便利用课余时间及时讲解，也难顾得上所有学生的需求，导致网课的效果比较有限。
                在“一块屏”项目落地的另一所中学巴中市南江县实验中学里，一份面向老师的调研也发现了类似情况
                六位老师均对网课班在教学业务提升方面，尤其是学习备课思路与教学方法、调整知识结构与提高专业能力上，给出了相当积极的评价；针对直播班与其他班教学进度不一的问题，有老师谈到会挑选合适的部分做讲解；在网班教学和非网班的学习热情上，老师们也发现网课班的学生要明显优于非网课班的学生。
                但另一方面，没有老师认为网课减轻了他们的工作压力。所有老师都表示自己要花费更多时间提醒学生专注。此外，大部分老师都认为网课减少了和学生的互动交流；也有小部分老师觉得自己从此失去了课堂的掌控权。
                过去两年多来，北京大学学生杨钰鑫一直跟踪引入了网班模式的县域中学，其中不乏一些已经引入网课、又常常徘徊于放弃和坚持两端的学校。经过与师生的交流，杨钰鑫发现，网班模式要想发挥成效，首先是县域中学的生源必须达到一定质量，能够跟上直播的教学进度；其次，当地教育部门和学校老师必须积极转变教学方式和教学态度来适应网班，帮助学生完成教学方式的跨越。
                “对一些基础不够的学生，直播教学让他们受挫感强，跟不上就越跟不上，还不如去普通班。”杨钰鑫说。
                <h2 style={{marginTop:'0.5rem',height:'auto'}}>找回屏幕下的自己</h2>
                “同样的材料，有人打造成一把好的钢刀；有的人拿来只能当作一把锄头；还有的人做了个浑身都是刺的东西，反过来把自己刺伤了。”肖继雄这样比喻这块连接两端的屏幕。
                2019年，蔺阳中学重启网课后，做出的最大改变就是将直播的网课改为录播，由老师选取屏幕里提到的知识点，分析其授课逻辑，再根据每个班的进度在课堂上教给学生。目前，蔺阳中学的高一年级分成了清北班、重点班和平行班，但哪个班都没有直播，而是由任课老师先听课，把听的课程转化成自己的能力，再将直播中画面截取成数个片段，制成微课，教给不同基础的学生。
                “哪怕从中学到一点或两点，再覆盖到全部学生，就是这块屏幕最大的功能。”肖继雄说。
                为了激励老师，蔺阳和马街中学的老师们每上完一节课，可以得到一定的经济回报，此外，如果老师可以在课堂上将七中的知识用好，学校再额外给予物质奖励。
                王光佑说，一开始采用这种模式时，学校会担心到底有多少老师可以真正按照设想认认真真去学，但后来，他们还是选择信任、鼓励大家。因为他相信，如果一位老师真正对自己负责，他肯定也希望自己能够更快、更好地成长。
                三年来，老师们也在行动上回馈了学校的信任。后来王光佑发现，虽然学校要求老师听课的总数不能超过七中的课时数，但是绝大多数的老师都显示出对知识的渴望，“哪怕你不给钱不算钱，大多数老师听课的数目还是远远超过了学校给老师规定的数目。” 
                “我们想通过自己的勤奋弥补差距，不可能永远被别人踩在脚下，还是要有斗志。”王光佑说。
                重启网课这些年来，蔺阳中学的成绩确实有了很大改变：2019年，该中学的重本人数为150人，2020年，人数变成209人，到了2021年，人数上升至253人。
                <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673406753630image+%286%29.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '0.5rem', marginBottom: '0.5rem', height: '28rem'}}></div>
                实际上，据网易公益“一块屏”项目负责人透露，三年来通过一系列实地走访调研，其项目组内部也逐渐形成了一种共识：与单纯培养清北学生相比，让这些县域高中实现整体教育水平的提升，更符合教育公平的本质。该负责人来自网易有道公益部门，作为网易旗下的教育科技板块，有道公司具体负责“一块屏”项目的落地。
                “虽然从数字上看，清北学生无论对学校招生还是项目执行机构的成绩，都是一种激励，但是对学校、对县域的整体发展，一两个尖子生可能带来不了太多改变，因为他们未来可能会因为行业原因留在大城市。”程肯是“一块屏”项目合作的公益组织秘书长，他表示，对县中而言，如果更多学生利用网课资源，让原来上不了本科的他们能上二本，原本只能上二本的孩子冲一本，目的就达到了。毕业之后，有人留在大城市，也有人回到县城，他们有了更多选择，这本身就很有意义。
                在杨钰鑫看来，激发县中的主体性变得尤为关键。她认为，输血不如造血，国家政策、企业注资、资源扶持，说到底都是为了帮县中成长起来。要实现这个目标，就不能只以成都七中的教学目标和节奏去要求县中保持一致，根本上，还是希望县中们走好自己的路。
                <h2 style={{marginTop:'0.5rem',height:'auto'}}>练就一颗“强大勇敢”的心</h2>
                几乎每一块屏幕，刚刚装进远端学校的课堂时，带给学生们的第一感受，都是沮丧。小小的天地突然被打开，涌来一群优秀的同龄人，讲出许多他们从没思考过的观念，他们谈电影、讲喜剧、评文学，在繁重的课业以外，还有各式各样的特长和爱好。
                当“硅谷”还只是地理课本上的一个地区名词时，成都七中已经有学子站起来分享他前往那里游历的经历，传到远端这一头，惊起教室里“哇”声一片。
                <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673406870191image+%287%29.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '0.5rem', marginBottom: '0.5rem', height: '28rem'}}></div>
                成都七中的“课前三分钟”学生展示环节对马街中学文科直播班的刘媛冲击最大。有的时候，她甚至觉得自己的自信心被屏幕那头的课堂和世界击碎。但这偏偏也是她在网课里最喜欢的那个部分。
                为此，她专门买了笔记本进行“课前摘录”，认真记下七中的同学们利用PPT、文档、演讲等各种形式分享的课外“精神世界”。“高中三年，每年都有不同的同学分享了《三体》，我太好奇了，就去买了一套。”刘媛指着六平米卧室里的书架，说起自己买书的冲动。在前端的“安利”下，《哈利·波特》《平凡的世界》《雪国》等五十多本古今中外的书，在过去三年里，都被刘媛一一汲取至脑海。
                而在政治课上，七中的同学们还会激情辩论热点与时事。“丁真现象”“离婚冷静期”“新闻反转事件”“偶像文化”……这些讨论让课程不再是枯燥的知识点，“让我们真的感觉大家是命运共同体，与人类息息相关，格局打开了”。
                <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673406909591image+%288%29.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '0.5rem', marginBottom: '0.5rem', height: '28rem'}}></div>
                徐梦兰曾因为屏幕那端老师对七中一位学生的批评，找到了那位同学的社交账号，发现了那个看上去“不努力”的女孩，课堂之外认真的另一面。三年间，徐梦兰也觉得自己的世界变得更大了，她曾经以为自己会沿着“大学—工作”这条路有条不紊地走下去，但看到屏幕对面的同龄人，她觉得自己体内烧起了一团火焰，或者去更大的城市，或者去考研，或者多修一个学位……
                求学阶段见识到世界的参差，反倒让这群孩子燃起了勇气。用刘媛的话说，班里对网络课程适应得最好的同学，已经练就了一颗“强大勇敢”的心脏，这体现在每一次直播课里，她能毫不拘束地表达自己，对热爱的英语课程投入了极高热情，每天都像“小马达一样充满了电”。
                “但我们仍然很庆幸有这一块屏幕。”刘媛说，“它可以像一块保护屏那样，将我们被击碎的内心稍微掩藏起来，然后留以时间让我们在废墟上去慢慢修复，又再次接受它，吸收它传递过来的知识营养，慢慢地，重新找回自尊、重建自信，让它不再轻易被打倒。”
                <div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673406910736image+%289%29.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '0.5rem', marginBottom: '0.5rem', height: '28rem'}}></div>
                这三年故事的尾声，是网易公益“一块屏”项目2022年做的调研报告，其中显示，远端学校中一些质量较高、容易跟得上网班的学校都已经步入正轨，但也存在质量更参差、凭自身能力很难跟上的县域高中。在教学输出技术已相当成熟的今天，网班模式的核心应从技术理性转向以人为本、转向县域高中、转向对多样技术使用者的关注。
                程肯表示，接下来，项目将着重提高县中领导对数字化教学的认知，让县中的教师们探索网班教育的新方法；再因校、因人制宜，根据学生的接受水平应用网班资源，惠及不同层次、不同水平的县中学生。
                “对县中的孩子而言，更重要的是找到自信。比如一个问题，一个故事，一篇文章，或者七中的老师讲得辛苦，而学生们一样不懂，这个时候，我们的学生反而会很兴奋，在那一刻，他们觉得七中的学生也不过如此。奠基他们未来美好生活的不仅是成绩，换一个角度，也许他们就能豁然开朗了。”王光佑说。
            </div>
            <div className='lineMiddleInArtical'></div>
            <div className='NewsRightInArticla'>
              {/* <div className="chineseIntro">相关报道</div> */}
              <PageRightNewsList
                Background={Background}
                title={'网易一块屏'}
                content={'从2018年起，网易公益发起“一块屏”项目，开始将网课直播引入偏远地区学校进行试点。2019年，网易公益“一块屏”在数字科技推动教育普惠上迈出了重要一步，为四川省十余个县超百所学校正式开设了网课直播班，上万学子因此受益。'}
                link={"/Artical/ScreenOfNews"}
              ></PageRightNewsList>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </>  
    )
}

export default ScreenOfNews