/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import './index.css'
import { scrollToAnchor } from '../../util'
import FundIntroduce from './pages/fundIntroduce'
import Community from './pages/community'
import Orgnization from './pages/orignzation'
import SupportUs from './pages/supportUs'
import ContactUs from './pages/contactUs'

const AboutContainer = (props) => {
    const { setPageState, setColor } = props
    const [hash, setTemp] = useState('')
    window.onhashchange=function(event){
        setTemp(event.newURL.split('#')[1])
        // headerNav(temp)
    }
    window.onload= (event) =>{
        window.location.hash = '#'
    }
    useEffect(()=>{
        setPageState('aboutUs')
        setColor('black')
    },[])
    return (
        <div className='aboutUsContent'>
            <div className="lineTop"></div>
            <div className="topBlank"></div>
            <nav className="aboutUsNavList">
                <a href="#/fund" onClick={() => scrollToAnchor('fund')}>{hash === '/fund' || hash === '' ? <span className="aboutUsTab">基金会介绍</span> : <span className="aboutUsTabNoAct">基金会介绍</span>}</a>
                <a href="#/community" onClick={() => scrollToAnchor('community')}>{hash === '/community' ? <span className="aboutUsTab">机构章程</span> : <span className="aboutUsTabNoAct">机构章程</span>}</a>
                <a href="#/orgnization" onClick={() => scrollToAnchor('orgnization')}>{hash === '/orgnization' ? <span className="aboutUsTab">组织架构</span> : <span className="aboutUsTabNoAct">组织架构</span>}</a>
                <a href="#/contactUs" onClick={() => scrollToAnchor('contactUs')}>{hash === '/contactUs' ? <span className="aboutUsTab">联系我们</span> : <span className="aboutUsTabNoAct">联系我们</span>}</a>
                <a href="#/supportUs" onClick={() => scrollToAnchor('supportUs')}>{hash === '/supportUs' ? <span className="aboutUsTab">支持我们</span> : <span className="aboutUsTabNoAct">支持我们</span>}</a>
            </nav>
            {hash === '/fund' || hash === '' ? <FundIntroduce></FundIntroduce> : null}
            {hash === '/community' ? <Community></Community>: null}
            {hash === '/orgnization' ? <Orgnization></Orgnization> : null}
            {hash === '/supportUs' ? <SupportUs></SupportUs> : null}
            {hash === '/contactUs' ? <ContactUs></ContactUs> : null}
        </div>
        )
}

export default AboutContainer