/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react' 
import { useEffect } from 'react'
import Footer from '../../Honor/footer'
import Background from '../../static/screen1.jpg'
import PageRightNewsList from '../components/PageRightNewsList'
import './index.css'

const VolunteerOfNews = (props) => {
  const {setColor} = props

  useEffect(()=>{
    setColor('black')
  }, [])
  return (
  <>
    <div className="lineTop"></div>
    <div className="topBlank"></div>
    {/* <div></div> */}
    <div style={{position: 'relative', height: '470rem'}}>
      <div className={'aboutUsContainer'}>
        <div className="content">
          <div className="englishIntro">The Volunteer</div>
          <div className="chineseIntro">志愿者公益</div>
          <div className="lineBehindNews"></div>
          <div className='newsContentInDetailPage'>
            <div className='PageleftNewsDetail'>
 10月23日，网易有道员工公益计划联合首都爱护动物协会共同举办了“关爱流浪动物公益活动”。50名有道员工来到首都爱护动物协会动物福利宣传教育中心，学习保护流浪小动物知识，并且一起为这些可爱的“小毛孩”们营造一个秋日打造“新家”。
 <h2 style={{height:'auto',marginTop:'0.5rem',color:'red'}}>14:00</h2>
志愿者们抵达动物福利宣传教育中心。几只疗愈犬跑入人群和大家亲密互动，热情活泼的模样瞬间萌化了志愿者们。在它们的陪伴下，动的岗前培训和知识学习变得非常欢乐有趣。
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652345WechatIMG526.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
<h2 style={{height:'auto',marginTop:'0.5rem',color:'red'}}>志愿者分成6个小队开始干活了！</h2>
狗狗小分队
带上特殊的手套和梳子，为狗狗们梳毛和按摩。
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673418287653WechatIMG527.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
趁着狗子们出门遛弯，志愿者们开始清扫它们的宿舍。等狗子们回来，就能看见焕然一新的房间啦！
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407651803WechatIMG528.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
志愿者们给狗狗洗澡。这只名叫小白的狗狗，每次洗澡时都会紧张地发抖。志愿者耐心地将它搂在怀里，擦拭身上的水渍。冲洗泡沫时，这只“小可爱”抖抖身体，甩出水花，逗得大家哈哈大笑。
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652052WechatIMG529.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
被生活“洗刷”、向往自由的小白
志愿者们为后肢残疾的小狗铺尿片，希望能够帮助到这只坚强的“小毛孩”。
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407651175WechatIMG530.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
<h2 style={{height:'auto',marginTop:'0.5rem',color:'red'}}>猫猫小分队</h2>
照顾小猫咪的一项重要工作，是使用逗猫棒帮助猫猫们锻炼。
志愿者们换上鞋套进入房间，猫咪们盯着志愿者手中的逗猫棒，蹦蹦跳跳地追逐嬉戏。
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652058WechatIMG531.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
志愿者们小心翼翼地照顾猫咪们，为它们换水喂食、铲猫砂，毛发过长的猫咪还需要另外的清洁、梳毛，在自己的小窝里温顺地等待志愿者们的“服务”。
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673418576864WechatIMG532.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
<h2 style={{height:'auto',marginTop:'0.5rem',color:'red'}}>16:00</h2>
完成工作的志愿者们开始和“毛孩子们”快乐玩耍。
夕阳下光影相接，志愿者和小狗一起荡秋千，共同享受着这宁静的下午时光。
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407653872WechatIMG533.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
小狗好奇地轻轻嗅闻，可爱的小动物和可爱的小朋友很快成为了新朋友。
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652184WechatIMG534.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
小猫咪在志愿者小姐姐的怀抱中酣睡，在这个短暂的下午感受“新家”的温暖。
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652932WechatIMG535.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
看见小伙伴靠在志愿者姐姐的怀里，狗子争着亲近小姐姐。
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652562WechatIMG536.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
<h2 style={{height:'auto',marginTop:'0.5rem',color:'red'}}>17:00</h2>
活动结束时，志愿者们恋恋不舍地和小动物们道别。不少志愿者都说，在这个短暂的下午里能够为小毛孩们打造“新家”，是一次很有意义的活动，也希望小毛孩们能真正收获一个家。
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652157WechatIMG537.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
<div style={{backgroundImage: `url(https://ydschool-video.nosdn.127.net/1673407652853WechatIMG538.jpeg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem'}}></div> 
            </div>
            <div className='lineMiddleInArtical'></div>
            <div className='NewsRightInArticla'>
              {/* <div className="chineseIntro">相关报道</div> */}
              <PageRightNewsList
                Background={Background}
                title={'网易一块屏'}
                content={'从2018年起，网易公益发起“一块屏”项目，开始将网课直播引入偏远地区学校进行试点。2019年，网易公益“一块屏”在数字科技推动教育普惠上迈出了重要一步，为四川省十余个县超百所学校正式开设了网课直播班，上万学子因此受益。'}
                link={"/Artical/ScreenOfNews"}
              ></PageRightNewsList>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </>  
    )
}

export default VolunteerOfNews