import React from 'react' 
import Footer from '../../../Honor/footer'
import { pdfjs } from 'react-pdf'
import './index.css'
import { Spin,Table } from 'antd'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const loadingStage = () => {
  return <div style ={{width: '5rem', height: '5rem'}}><Spin size="large"></Spin></div>
}

const data = [
  {name:'北京网易公益基金会年报',time:'2023年9月',href:'https://ydschool-video.nosdn.127.net/17163442690912023_jijinhui.pdf'},
  {name:'北京网易公益基金会年报',time:'2022年9月',href:'https://ydschool-video.nosdn.127.net/17163442691232022_jijinhui.pdf'},
  {name:'北京网易公益基金会年报',time:'2021年9月',href:'https://ydschool-video.nosdn.127.net/17163442691242021_jijinhui.pdf'},
  {name:'北京网易公益基金会年报',time:'2020年9月',href:'https://ydschool-video.nosdn.127.net/17163442702902020_jijinhui.pdf'},
  {name:'北京网易公益基金会年报',time:'2019年9月',href:'https://ydschool-video.nosdn.127.net/17163442704242019_jijinhui.pdf'},
  {name:'北京网易公益基金会年报',time:'2018年9月',href:'https://ydschool-video.nosdn.127.net/17163442707282018_jijinhui.pdf'},
  {name:'北京网易公益基金会年报',time:'2017年9月',href:'https://ydschool-video.nosdn.127.net/17163442705972017_jijinhui.pdf'}
];
const columns=[
  {
    title: '基金年报',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '时间',
    dataIndex: 'time',
    key: 'time',
  }
]


const YearsReport = () => {
  
  return (
  <div style={{position: 'relative', height: '92rem'}}>
    <div className={'aboutUsContainer'}>
      <div className="content">
        <div className="englishIntro">Foundation Annual Report</div>
        <div className="chineseIntro">基金会年报</div>
       <Table columns={columns} dataSource={data} style={{marginTop:"20px"}} 
       onRow={record => {
        return {
          onClick: event => {window.location.href=record.href}, 
        };
      }} />
        </div>
    </div>
    <Footer></Footer>
  </div>)
}

export default YearsReport