import React from 'react'
import './index.css'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Navigation } from 'swiper';
import SwiperCore from 'swiper/core/core';
import 'swiper/swiper.min.css'

SwiperCore.use([Navigation])
const PartnerContainer = () => {
    return (
    <div className="containerOfPartner" id="partner">
        <div className="containerRightPartner">
            <div className="topContainerPartner">
                <div className="imgList">
                    <div className="imgListLeft">
                        <div className="imgListLeftFront"></div>
                        <div className="imgListLeftEnd"></div>
                    </div>
                    <div className="imgListRight">
                        <span className="topName">感谢与我们一起</span>
                        <span className="bottomName">并肩作战的伙伴们</span>
                    </div>
                </div>
            </div>
            <div className="bottomContainerPartner">
            <Swiper
                spaceBetween={0}
                slidesPerView={1}
                // slideToClickedSlide={true}
                // preventClicks={false}
                // centeredSlides={true}
                onSlideChange={() => {}}
                onSwiper={(swiper) => {}}
                initialSlide={0} // 初始化显示哪一个
                loop={false} // 是否循环
                preventDefault={false}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  }}
                >
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
                <SwiperSlide>
                    <div className="bottomLogoList">
                        <div className="game"></div>
                        <div className="news"></div>
                        <div className="youdao"></div>
                        <div className="music"></div>
                        <div className="social"></div>
                        <div className="fund"></div>
                        <div className="child"></div>
                        <div className="redcross"></div>
                        <div className="charity"></div>
                        <div className="worldedu"></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="bottomLogoList">
                        <div className="thumbnail"></div>
                        <div className="yeshengjiuyuan"></div>
                        <div className="greenpeace"></div>
                        <div className=""></div>
                        <div className=""></div>
                        <div className=""></div>
                        <div className=""></div>
                        <div className=""></div>
                        <div className=""></div>
                        <div className=""></div>
                    </div>
                </SwiperSlide>
            </Swiper>
            </div>
        </div>
        <div className="leftScorll3">
            <div className="topTextLeftPartner"></div>
            <div className="topTextRightPartner"></div>
            <div className="bottomIconPartner"></div>
        </div>
    </div>)
}

export default PartnerContainer