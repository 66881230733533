import React from 'react'
import './index.css'

const NewsContainer = () => {
    return (
    <div className="containerOfNews" id="news">
        <div className="containerRightOfNews">
            <div className="TopContainerOfNews">
            <div className="leftContainerOfNewsTopImg">
                <a href="https://mp.weixin.qq.com/s/8LUlkc5dtqVJ7EOE6oQezQ"> 
                    <span className="introLeftEnglishTextTop1" style={{color:"white"}}> <span>2021.12.10</span></span><br/>
                    <span className="introLeftChineseText1" style={{color:"white"}}>乡村振兴 教育同行</span>
                </a>
            </div>
            <div className="rightContainerOfNewsTopImg">
                <a href="https://mp.weixin.qq.com/s/eJZVyjhyXPp51AHA0Rdupg" style={{borderTop:"1px solid #ccc"}}>
                    <span className="introLeftEnglishTextTop" style={{color:"rgb(146, 145, 145)"}}>2021.12.10</span><br/>
                    <span className="introLeftChineseTextTop" style={{color:"black"}}>北京网易公益基金会正式加入中国慈善联合会</span>
                    <div className='heartImgGongyi'></div>
                </a>
            </div>
            </div>
            <div className="bottomContainerOfNews">
                <div className="rightContainerOfNewsBottomImg">
                <a href="https://mp.weixin.qq.com/s/UKFeBQ_nl6djr38FwlLc3w" style={{borderTop:"1px solid #ccc"}}>
                    <span className="introLeftEnglishTextTop" style={{color:"rgb(146, 145, 145)"}}>2022.03.14</span><br/>
                    <span className="introLeftChineseTextTop" style={{color:"black"}}>微光计划公益行动上线——开启“春天的事业”</span><br/>
                    <span className="introLeftChineseTextBottom" style={{color:"black"}}>3月14日，网易有道、网易云课堂联合微软推出大型教育助残公益行动“微光计划”</span>
                </a>
                </div>
                <div className='centerContainerOfNewsBottomImg'>
                <a href="https://mp.weixin.qq.com/s/BitFia13S6Sq_5Yk_jcqKg" style={{backgroundColor:"red"}}>
                    <span className="introLeftEnglishTextTop">2021.12.10</span><br/>
                    <span className="introLeftChineseTextTop" style={{color:"white"}}>网易有道公益再获社会认可2021年度最佳责任企业品牌奖</span><br/>
                    <div className='rewardImgGonyi'></div>
                    <span className='seeClicks'>点击查看详情</span>
                </a>
                </div>
                <div className="leftContainerOfNewsBottomImg">
                <a href="https://mp.weixin.qq.com/s/_WH_Vshgwfk2OxXjdB18tQ" style={{borderTop:"1px solid #ccc"}}>
                    <span className="introLeftEnglishTextTop" style={{color:"rgb(146, 145, 145)"}}>2021.12.10</span><br/>
                    <span className="introLeftChineseTextTop" style={{color:"black"}}>网易有道加入信息无障碍产品联盟，致力“人人享受教育科技”</span><br/>
                    <span className="introLeftChineseTextBottom" style={{color:"black"}}>近日，网易有道正式加入信息无障碍产品联盟（简称CAPA），成为联盟成员单位</span>
                </a>
                </div>
            </div>
        </div>
        <div className="leftScorll2">
            <div className="topTextLeft"></div>
            <div className="topTextRight"></div>
            <div className="bottomIcon"></div>
        </div>
    </div>)
}

export default NewsContainer