import React from 'react'
import { Link } from "react-router-dom";
const NewsModalPicLeft = (props) => {
  const {Background, title, content, link} = props

  const params = link.split(':')[0]
  return (
    <div className="PicLeftArtical">
      <div className="articalImg" style={{backgroundImage: `url(${Background})`}}></div>
      <div className="articalText">
        <div className="topArticalSpan">
          <div className="topArticalTitle">{title}</div>
          <div className="topArticalContent">{content}</div>
        </div>
        {params.indexOf('http') ? <Link className="BottomArticalLink" to={link} target='_blank'>
          <div className="articalLinkText">查看详情</div>
          <div className="articalLinkArrow"></div>
        </Link> : <a className="BottomArticalLink" href={link} target='_blank' rel="noreferrer">
          <div className="articalLinkText">查看详情</div>
          <div className="articalLinkArrow"></div>
        </a>}
      </div>
</div>
  )
}

export default NewsModalPicLeft