import React from 'react' 
import Footer from '../../../Honor/footer'
import NewsModalPicLeft from '../../../MessageCenter/components/NewsModalPicLeft'
import NewsModalPicRight from '../../../MessageCenter/components/NewsModalPicRight'
import pic1 from '../../../static/access1.jpg'
import pic2 from '../../../static/nana.png'

export const Accessibility = () => {
  return ( <div style={{position: 'relative', height: '86rem'}}>
  <div className={'aboutUsContainer'}>
    <div className="content">
      <div className="englishIntro">Information Accessibility </div>
      <div className="chineseIntro">信息无障碍</div>
      <div className="projectContent">
        <NewsModalPicLeft
          Background={pic1}
          title={'网易有道加入信息无障碍产品联盟，致力“人人享受教育科技”。'}
          content="2021年10月15日，网易有道宣布正式加入信息无障碍产品联盟（简称CAPA），成为联盟成员单位。这是网易有道践行企业社会责任的又一关键动作，也意味着接下来网易有道将在公益事业上投入更多精力和资源。"
          link="/Artical/InformationOfNews"
        ></NewsModalPicLeft>
        <NewsModalPicRight
          Background={pic2}
          position={'50%'}
          size={'cover'}
          title={'123无障爱'}
          content="众星齐助力#123无障爱# ，网易有道词典将陪伴1700万视障用户学习英语"
          link="/Artical/InformationSecondOfNews"
        ></NewsModalPicRight>
      </div>
    </div>
  </div>
  <Footer></Footer>
</div>)
}

export default Accessibility