import React from 'react'
import { Link } from "react-router-dom";

const NewsModalPicLeft = (props) => {
  const {Background, title, content, link, position = '50%', size='cover'} = props
  return (
    <div className="PicRightArtical">
      <div className="articalText">
        <div className="topArticalSpan">
          <div className="topArticalTitle">{title}</div>
          <div className="topArticalContent">{content}</div>
        </div>
        <Link className="BottomArticalLink" to={link} target="_blank">
          <div className="articalLinkText">查看详情</div>
          <div className="articalLinkArrow"></div>
        </Link>
      </div>
      <div className="articalImg" style={{backgroundImage: `url(${Background})`, backgroundSize: size, backgroundPosition: position }}></div>
    </div>
  )
}

export default NewsModalPicLeft