/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react' 
import { useEffect } from 'react'
import Footer from '../../Honor/footer'
import Background from '../../static/screen1.jpg'
import PageRightNewsList from '../components/PageRightNewsList'
import './index.css'

const CornerOfNews = (props) => {
  const {setColor} = props

  useEffect(()=>{
    setColor('black')
  }, [])
  return (
  <>
    <div className="lineTop"></div>
    <div className="topBlank"></div>
    {/* <div></div> */}
    <div style={{position: 'relative', height: '162rem'}}>
      <div className={'aboutUsContainer'}>
        <div className="content">
          <div className="englishIntro">Youdao Corner</div>
          <div className="chineseIntro">有道外语阅读角</div>
          <div className="lineBehindNews"></div>
          <div className='newsContentInDetailPage'>
            <div className='PageleftNewsDetail'>
            以智能和关怀，减轻英语学习障碍，点亮困境儿童未来。
            “有道外语阅读角”是北京网易公益基金会联合网易有道于2020年9月发起的一项公益项目，主要服务对象为困境儿童（乡村儿童及流动儿童）。通过向学校和困境儿童服务公益机构提供智能硬件、外文图书和资金，支持其打造外语阅读角，旨在帮助困境儿童享受优质的教育资源，培养和提升困境儿童的英语学习兴趣和学习能力。
            <div style={{backgroundImage: `url(https://shimo.youdao.com/uploader/f/QXd0EeU7GVjQ4ZHl.jpg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '44rem', backgroundPosition: '50%'}}></div>
               

            2021年是中国共产党建党100周年，也是乡村振兴的开局之年，网易公益教育关注中西部革命老区教育发展，在这特殊年度网易有道联合北京网易公益基金会开展了“点亮长征路——有道词典笔致敬建党100周年系列活动。为中央红军长征路上10个革命老区捐赠了有道外语阅读角，助力点亮革命老区孩子们的梦想之光。本次公益活动弘扬了红色历史文化，助力革命老区的教育发展。通过为长征时期中央红军途经的中西部地区援建有道外语阅读角以及开展多样的阅读活动，以此铭记党的历史，为革命老区的孩子们打开更多更大世界的想象力。
            <div style={{backgroundImage: `url(https://shimo.youdao.com/uploader/f/APu3Bupc8rOaX5La.jpg)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem', backgroundPosition: '50%'}}></div>
    
            <div style={{backgroundImage: `url(https://shimo.youdao.com/uploader/f/BL5boijCa9N6K9ie.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', marginTop: '1rem', marginBottom: '1rem', height: '28rem', backgroundPosition: '50%'}}></div>
    
              截至目前，基金会已在全国20余省市建设40个有道外语阅读角，并组织开展多样的英语学习活动，近6万人次困境儿童受益。因项目的创新性和普惠性，项目荣获2021年度“金钥匙·优质教育荣誉奖”。
            </div>
            <div className='lineMiddleInArtical'></div>
            <div className='NewsRightInArticla'>
              {/* <div className="chineseIntro">相关报道</div> */}
              <PageRightNewsList
                Background={Background}
                title={'网易一块屏'}
                content={'从2018年起，网易公益发起“一块屏”项目，开始将网课直播引入偏远地区学校进行试点。2019年，网易公益“一块屏”在数字科技推动教育普惠上迈出了重要一步，为四川省十余个县超百所学校正式开设了网课直播班，上万学子因此受益。'}
                link={"/Artical/ScreenOfNews"}
              ></PageRightNewsList>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </>  
    )
}

export default CornerOfNews